import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatIconModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatTabsModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { CoreModule } from '@app/core';
import { ReservationRoutingModule } from '../reservation-routing.module';
import { ReservationIntegrationComponent } from './reservation-integration.component';
import { SharedModule } from '@app/shared';
import { LazyElementsModule } from '@angular-extensions/elements';
import { NgEncodeURIPipeModule } from '@app/shared/encode-uri/encode-uri';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReactiveFormsModule } from '@angular/forms';
import { FontPickerModule } from 'ngx-font-picker';
import { ReservationWidgetWrapperModule } from '../../../../projects/elements/src/app/reservation-widget/reservation-widget.module';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { InfoTextModule } from '../../../../projects/reservation/src/app/reservation/info-text/info-text.module';
import { GoogleTranslateModule } from '../../../../projects/reservation/src/app/reservation/google-translate/google-translate.module';
import { PmsIntegrationComponent } from './pms-integration/pms-integration.component';
import { NgSelectModule } from '@ng-select/ng-select';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  apiKey: 'AIzaSyAyAtE7GNzI6Y6Vep7WrPCQ05VADtP8ztM'
};
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    ReservationRoutingModule,
    MatIconModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatExpansionModule,
    LazyElementsModule,
    NgScrollbarModule,
    MatButtonToggleModule,
    NgEncodeURIPipeModule,
    ReactiveFormsModule,
    FontPickerModule,
    ReservationWidgetWrapperModule,
    ColorPickerModule,
    InfoTextModule,
    GoogleTranslateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    NgSelectModule,
    MatProgressSpinnerModule
  ],
  declarations: [ReservationIntegrationComponent, PmsIntegrationComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG
    }
  ]
})
export class ReservationIntegrationModule {}
