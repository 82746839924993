/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner-invoice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./banner-invoice.component";
import * as i4 from "@angular/router";
import * as i5 from "../../core/client.service";
import * as i6 from "../../core/authentication/authentication.service";
var styles_BannerInvoiceComponent = [i0.styles];
var RenderType_BannerInvoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerInvoiceComponent, data: {} });
export { RenderType_BannerInvoiceComponent as RenderType_BannerInvoiceComponent };
function View_BannerInvoiceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\n    Du hast noch ", " offene Rechnungen mit einem Gesamtbetrag von ", " EUR. Bitte\n    begleiche diese zeitnah um ein reibungsloses Arbeiten zu erm\u00F6glichen.\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-light ng-star-inserted"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToInvoices() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Zu den Rechnungen"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingInvoices; var currVal_1 = _co.totalAmount; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_BannerInvoiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerInvoiceComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBanner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BannerInvoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, View_BannerInvoiceComponent_0, RenderType_BannerInvoiceComponent)), i1.ɵdid(1, 114688, null, 0, i3.BannerInvoiceComponent, [i4.Router, i5.ClientService, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerInvoiceComponentNgFactory = i1.ɵccf("app-banner", i3.BannerInvoiceComponent, View_BannerInvoiceComponent_Host_0, {}, {}, []);
export { BannerInvoiceComponentNgFactory as BannerInvoiceComponentNgFactory };
