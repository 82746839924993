import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { OccupancyIndexRoutingModule } from './occupancy-index-routing.module';
import { OccupancyIndexComponent } from './occupancy-index.component';
import { ReservationsModule } from '@app/reservation/reservations/reservations.module';
import { DateFnsModule } from 'ngx-date-fns';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [OccupancyIndexComponent],
  imports: [
    CommonModule,
    DateFnsModule.forRoot(),
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    OccupancyIndexRoutingModule,
    FormsModule,
    SharedModule,
    ReservationsModule
  ],
  exports: [OccupancyIndexComponent]
})
export class OccupancyIndexModule {}
