import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeedbackSettings } from './google-reviews.model';

@Component({
  selector: 'app-google-reviews',
  templateUrl: './google-reviews.component.html',
  styleUrls: ['./google-reviews.component.scss']
})
export class GoogleReviewsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  public feedbackSettings: FeedbackSettings = {
    googleIntegration: false,
    googleDirectLink: false,
    minimumStars: 0,
    googleLink: ''
  };

  public hoveredStars: number = 0;
  public minimumStars: number = 0;

  constructor(private clientService: ClientService) {}

  ngOnInit() {
    this.getFeedbackSettings();
  }

  getFeedbackSettings() {
    this.clientService
      .getFeedbackSettings()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: FeedbackSettings) => {
        this.feedbackSettings = { ...res };
        this.minimumStars = this.feedbackSettings.minimumStars;
      });
  }

  setStars(stars: number) {
    this.minimumStars = stars;
    this.feedbackSettings.minimumStars = stars;
  }

  saveSettings() {
    console.log(this.feedbackSettings);
    this.clientService
      .saveFeedbackSettings(this.feedbackSettings)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          console.log('Settings saved:', response);
        },
        error => {
          console.error('Error saving settings:', error);
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
