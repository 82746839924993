import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ReservationService } from '@app/reservation/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { HOTEL_SYSTEM_CONFIG } from '@app/core/constants';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-pms-integration',
  templateUrl: './pms-integration.component.html',
  styleUrls: ['./pms-integration.component.scss']
})
export class PmsIntegrationComponent implements OnInit, OnDestroy {
  HOTEL_SYSTEM_FIELDS: { [system: string]: any[] } = {};

  // Dropdown options are built from HOTEL_SYSTEM_CONFIG keys.
  hotelSystems = Object.keys(HOTEL_SYSTEM_CONFIG).map(system => ({
    id: system, // e.g. "Oracle", "ASA", etc.
    value: system // Display text is the same.
  }));

  dynamicForm: FormGroup;
  selectedHotelSystem: string = '';
  dropdownSelected: string | null = null;

  integrationData: any[] = [];
  systemIntegrations: { system: string; data: any[] }[] = [];
  loading: boolean = false;
  clientId: string;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private reservationService: ReservationService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.buildHotelSystemFields();
    this.loadInitialData();
  }

  private buildHotelSystemFields() {
    // Default validators
    const defaultValidators = [Validators.required];

    // Build dynamic fields from HOTEL_SYSTEM_CONFIG
    Object.keys(HOTEL_SYSTEM_CONFIG).forEach(system => {
      this.HOTEL_SYSTEM_FIELDS[system] = HOTEL_SYSTEM_CONFIG[system].map(field => ({
        ...field,
        type: 'text', // override type to text
        validators: field.validators ? field.validators : defaultValidators
      }));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadInitialData() {
    this.loading = true;
    this.reservationService
      .getPMSData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any[]) => {
          this.integrationData = data;
          this.processIntegrationData();
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.snackBar.open(this.translate.instant('PMSIntegration.SomethingWentWrong'), 'Close', { duration: 2000 });
        }
      );
  }

  processIntegrationData() {
    const groupedIntegrations = this.integrationData.reduce((acc, item) => {
      const sys = item.hotelSystem; // Expected to match exactly as in HOTEL_SYSTEM_CONFIG
      if (!acc[sys]) {
        acc[sys] = [];
      }
      acc[sys].push(item);
      return acc;
    }, {} as Record<string, any[]>);

    this.systemIntegrations = Object.entries(groupedIntegrations).map(([system, data]) => ({
      system,
      data: data as any[]
    }));

    // Auto-select if exactly one system has data.
    if (this.systemIntegrations.length === 1) {
      const singleSystem = this.systemIntegrations[0].system;
      this.dropdownSelected = singleSystem;
      this.selectHotelSystem(singleSystem);
    } else {
      this.dropdownSelected = null;
      this.selectedHotelSystem = '';
      this.dynamicForm = null;
    }
  }

  onDropdownChange(event: any) {
    if (!event || !event.value) {
      this.selectedHotelSystem = '';
      this.dynamicForm = null;
      return;
    }
    const systemName = event.value;
    this.selectHotelSystem(systemName);
  }

  selectHotelSystem(system: string) {
    if (!HOTEL_SYSTEM_CONFIG[system]) {
      this.snackBar.open(this.translate.instant('PMSIntegration.SomethingWentWrong'), 'Close', { duration: 2000 });
      return;
    }
    this.selectedHotelSystem = system;
    this.dropdownSelected = system;
    this.createDynamicForm(system);
  }

  createDynamicForm(system: string) {
    const formConfig: any = {};
    const fields = this.HOTEL_SYSTEM_FIELDS[system] || [];
    fields.forEach((field: any) => {
      const match = this.integrationData.find(item => item.hotelSystem === system && item.key === field.key);
      formConfig[field.key] = [match ? match.value : '', field.validators];
    });
    this.dynamicForm = this.fb.group(formConfig);
  }

  submitData() {
    if (!this.selectedHotelSystem) {
      this.snackBar.open(this.translate.instant('PMSIntegration.SelectHotelIntegrations'), 'Close', { duration: 2000 });
      return;
    }
    if (!this.dynamicForm || this.dynamicForm.invalid) {
      this.snackBar.open(this.translate.instant('PMSIntegration.SomethingWentWrong'), 'Close', { duration: 2000 });
      return;
    }
    const formData = this.dynamicForm.value;
    const resultArray = Object.entries(formData).map(([key, value]) => {
      const existing = this.integrationData.find(
        item => item.hotelSystem === this.selectedHotelSystem && item.key === key
      );
      return {
        id: existing ? existing.id : null,
        hotelSystem: this.selectedHotelSystem,
        key,
        clientId: this.clientId,
        value
      };
    });
    this.loading = true;
    this.reservationService
      .createPMSData({
        data: resultArray,
        clientId: this.clientId,
        type: this.selectedHotelSystem
      })
      .subscribe(
        () => {
          this.loading = false;
          this.snackBar.open(this.translate.instant('PMSIntegration.Success'), 'Close', { duration: 2000 });
          this.loadInitialData();
        },
        error => {
          this.loading = false;
          this.snackBar.open(this.translate.instant('PMSIntegration.SomethingWentWrong'), 'Close', { duration: 2000 });
          console.error(error);
        }
      );
  }

  hasExistingData(): boolean {
    return this.integrationData.some(item => item.hotelSystem === this.selectedHotelSystem);
  }

  deleteData() {
    if (!this.hasExistingData()) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.translate.instant('PMSIntegration.DeleteIntegration');
    modalRef.componentInstance.message = this.translate.instant('PMSIntegration.AreYouSureDelete'); // Create key accordingly
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.translate.instant('PMSIntegration.Yes');

    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.loading = true;
          this.reservationService
            .deletPMSData({
              clientId: this.clientId,
              type: this.selectedHotelSystem
            })
            .subscribe(() => {
              this.loading = false;
              this.loadInitialData();
              if (this.dynamicForm) {
                this.dynamicForm.reset();
              }
              this.snackBar.open(this.translate.instant('PMSIntegration.Success'), 'Close', { duration: 2000 });
            });
        }
      },
      () => {
        this.loading = false;
      }
    );
  }
}
