<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Google Reviews Settings</h6>

        <div class="element-box">
          <form (ngSubmit)="saveSettings()" class="settings-form">
            <div class="form-group">
              <h5 class="form-header">
                {{ 'FeedBack.GoogleReview.Integration' | translate }}
              </h5>
              <mat-slide-toggle
                name="googleIntegration"
                [checked]="!!feedbackSettings.googleIntegration"
                (change)="feedbackSettings.googleIntegration = $event.checked"
              >
              </mat-slide-toggle>
            </div>
            <div class="form-group">
              <h5 class="form-header">{{ 'FeedBack.GoogleReview.DirectLink' | translate }}</h5>
              <mat-slide-toggle
                name="googleDirectLink"
                [checked]="!!feedbackSettings.googleDirectLink"
                (change)="feedbackSettings.googleDirectLink = $event.checked"
              >
              </mat-slide-toggle>
            </div>
            <div class="form-group">
              <h5>{{ 'FeedBack.GoogleReview.MinimumStars' | translate }}</h5>
              <div class="stars">
                <i
                  *ngFor="let star of [1, 2, 3, 4, 5]"
                  class="fa-star"
                  [ngClass]="star <= minimumStars ? 'fas' : 'far'"
                  (mouseover)="hoveredStars = star"
                  (mouseleave)="hoveredStars = minimumStars"
                  (click)="setStars(star)"
                >
                </i>
              </div>
            </div>

            <div class="form-group">
              <h5>{{ 'FeedBack.GoogleReview.GoogleLink' | translate }}</h5>
              <input
                type="text"
                id="googleLink"
                class="form-control"
                [(ngModel)]="feedbackSettings.googleLink"
                name="googleLink"
              />
            </div>

            <button type="submit" class="btn btn-primary">{{ 'FeedBack.GoogleReview.Button' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
