import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import format from 'date-fns/format';
import { ReservationService } from '../reservation.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, Constants } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { PMSIntegrationService } from './pms-integration.service';
import { Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { ReplaceGuestReservationComponent } from './replace-guest-reservation/replace-guest-reservation.component';
import { addDays, subDays } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-hotel-reservation',
  templateUrl: './hotel-reservation.component.html',
  styleUrls: ['./hotel-reservation.component.scss']
})
export class HotelReservationComponent implements OnInit, OnDestroy {
  @ViewChild('myTable', { static: false }) table: any;
  subscriptions: Subscription[] = [];
  reservationFormGroup: FormGroup;
  isLoadingAutocomplete: boolean = false;
  // filteredGuests: any[] = [];
  restaurants: any = [];
  times: any[] = [];
  incompatibilities: any[] = [];
  areAllTablesFreeAtSelectedTime: boolean = true;
  clientId: number;
  minArrivalDate: Date;
  minDepartureDate: Date;
  // stayTimeIsNotSelected: boolean = true;
  // stayTimeErrorMsg = 'Bitte wählen Sie zuerst die Aufenthaltsdauer.';
  tags: any[];
  previousArrivalDate: Date;
  previousDepartureDate: Date;
  stayTimes = [
    // { name: `30 ${this.translate.instant('HotelReservations.Minutes')}`, value: 30 },
    { name: `1 ${this.translate.instant('HotelReservations.Hour')}`, value: 60 },
    { name: `1.5  ${this.translate.instant('HotelReservations.Hours')}`, value: 90 },
    { name: `2  ${this.translate.instant('HotelReservations.Hours')}`, value: 120 },
    { name: `2.5  ${this.translate.instant('HotelReservations.Hours')}`, value: 150 },
    { name: `4  ${this.translate.instant('HotelReservations.Hours')}`, value: 240 }
  ];
  private ngUnsubscribe: Subject<any> = new Subject();
  hotelReservations: any = [];
  tableLimit: number = 25;
  copyAllItems: boolean = false;
  defaultTime: string;
  mealTypeMap: { [key: string]: string } = {
    breakfast: this.translate.instant('HotelReservations.Breakfast'),
    lunch: this.translate.instant('HotelReservations.Lunch'),
    dinner: this.translate.instant('HotelReservations.Dinner')
  };
  breakfastCounter: number = 1;
  lunchCounter: number = 1;
  dinnerCounter: number = 1;

  mealOptions = [
    { value: 'FR', label: this.translate.instant('HotelReservations.Breakfast'), color: 'lightsalmon' },
    { value: 'HP', label: this.translate.instant('HotelReservations.HalfBoard'), color: 'lightpink' },
    { value: 'VP', label: this.translate.instant('HotelReservations.FullBoard'), color: 'lightblue' }
  ];
  mealTypeOrder: { [key: string]: number } = {
    dinner: 1,
    lunch: 2,
    breakfast: 3
  };
  allHotelReservations: any = [];
  submitObservable: any;
  dates: any = {
    from: '',
    to: ''
  };
  showErrorMessage: boolean = false;
  pmsIntegrations: any[] = [];
  multipleIntegration: boolean = false;
  loadingHotelRes: boolean = false;
  selectedIntegration: any;
  callPlaced: boolean = false;

  pageSize = 10000;
  currentPage = 1;
  totalPages = 1;
  paginatedReservations: any = [];
  searchTerm: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private pmsIntegrationService: PMSIntegrationService,
    private clientService: ClientService,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {});
  }

  ngOnInit() {
    this.setDates('thisWeek');
    this.initForm();
    this.getTimes();
    this.subscriptions.push(
      this.reservationService.getPMSDetails().subscribe(
        res => {
          if (res && res.length) {
            this.pmsIntegrations = [
              {
                label: Constants.hotelIntegrations.Oracle,
                showBtn: !!res.find((item: any) => item.hotelSystem === Constants.hotelIntegrations.Oracle),
                onClick: () => this.getHotelReservationOracle()
              },
              {
                label: Constants.hotelIntegrations.ASA,
                showBtn: !!res.find((item: any) => item.hotelSystem === Constants.hotelIntegrations.ASA),
                onClick: () => this.getHotelReservationASA()
              },
              {
                label: Constants.hotelIntegrations.ibelsa,
                showBtn: !!res.find((item: any) => item.hotelSystem === Constants.hotelIntegrations.ibelsa),
                onClick: () => this.getHotelReservationIbelsa()
              },
              {
                label: Constants.hotelIntegrations.Casablanca,
                showBtn: !!res.find((item: any) => item.hotelSystem === Constants.hotelIntegrations.Casablanca),
                onClick: () => this.getHotelReservationCasablanca()
              }
            ].filter(integration => integration.showBtn); // Remove integrations that are not available

            // Check if there are multiple integrations
            this.multipleIntegration = this.pmsIntegrations.length > 1;
          } else {
            this.pmsIntegrations = [];
            this.multipleIntegration = false;
          }
        },
        err => {
          this.pmsIntegrations = [];
          this.multipleIntegration = false;
        }
      )
    );

    this.subscriptions.push(
      this.reservationService.getAllHotelReservations().subscribe(
        res => {
          if (res) {
            this.allHotelReservations = res.map((item: any) => item.hotelReservation.bookingNumber);
          }
        },
        err => {
          this.allHotelReservations = [];
        }
      )
    );

    this.subscriptions.push(
      this.authService.getActiveClient().subscribe(data => {
        this.clientId = data.id;
      })
    );

    this.subscriptions.push(
      this.reservationService.getRooms().subscribe(rooms => {
        this.restaurants = rooms.filter(function(room: any) {
          return room.isActive == 1;
        });
      })
    );

    this.subscriptions.push(
      this.reservationService.getIncompatibilities().subscribe(incompatibilities => {
        this.incompatibilities = incompatibilities.data;
      })
    );

    // this.minArrivalDate = new Date();
    // this.minDepartureDate = new Date();

    this.subscriptions.push(
      this.reservationService.getTags().subscribe((tags: any) => {
        this.tags = tags;
      })
    );
  }

  onSelectIntegration(selectedIntegration: any) {
    this.selectedIntegration = selectedIntegration;
  }

  triggerLoading(index: number) {
    this.loadingHotelRes = true;
    this.pmsIntegrations[index].onClick();
    this.reservationFormGroup.get('hotelSystem').setValue(this.pmsIntegrations[index].label);
  }

  createNumValues(number: number): any[] {
    const items: any[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  addNewReservationGroup(date: Date, mealType: any): FormGroup {
    const group = this.formBuilder.group({
      date: [date, Validators.required],
      time: [null, Validators.required],
      listOfTimes: [this.times, Validators.required],
      listOfRestaurants: [this.restaurants, Validators.required],
      restaurant: [null, Validators.required],
      table: [null],
      listOfTables: [[]],
      errorMessage: [''],
      isEditable: [false],
      mealType: [mealType, Validators.required],
      resIndex: [],
      tableLoader: false,
      exempt: [false]
    });

    // Watch for changes in `exempt`
    group.get('exempt').valueChanges.subscribe(exempt => {
      if (exempt) {
        group.get('date').clearValidators();
        group.get('time').clearValidators();
        group.get('listOfTimes').clearValidators();
        group.get('listOfRestaurants').clearValidators();
        group.get('restaurant').clearValidators();
        // group.get('table').clearValidators();
        group.get('mealType').clearValidators();
      } else {
        group.get('date').setValidators(Validators.required);
        group.get('time').setValidators(Validators.required);
        group.get('listOfTimes').setValidators(Validators.required);
        group.get('listOfRestaurants').setValidators(Validators.required);
        group.get('restaurant').setValidators(Validators.required);
        // group.get('table').setValidators(Validators.required);
        group.get('mealType').setValidators(Validators.required);
      }
      group.get('date').updateValueAndValidity({ emitEvent: false });
      group.get('time').updateValueAndValidity({ emitEvent: false });
      group.get('listOfTimes').updateValueAndValidity({ emitEvent: false });
      group.get('listOfRestaurants').updateValueAndValidity({ emitEvent: false });
      group.get('restaurant').updateValueAndValidity({ emitEvent: false });
      // group.get('table').updateValueAndValidity({ emitEvent: false });
      group.get('mealType').updateValueAndValidity({ emitEvent: false });
    });

    return group;
  }

  addNewReservationFormAtIndex(date: Date, index: number, mealType: any = '') {
    (<FormArray>this.reservationForm).insert(index, this.addNewReservationGroup(date, mealType));
  }

  deleteReservationFromArray(index: number) {
    (<FormArray>this.reservationForm).removeAt(index);
  }

  displayFnName(guest: any): string {
    if (guest) {
      this.reservationFormGroup.get('incompatibilities').setValue(JSON.parse(guest.intolerance));
      return guest.name;
    }
  }

  createReservation(): void {
    // if everything is ok create new reservations
    if (this.reservationFormGroup.valid) {
      this.processReservations();
    }

    // check if input form is invalid without reservations form
    if (
      !this.reservationFormGroup.controls.name.valid ||
      !this.reservationFormGroup.controls.roomNumber.valid ||
      !this.reservationFormGroup.controls.reservationNumber.valid ||
      !this.reservationFormGroup.controls.arrivalDate.valid ||
      !this.reservationFormGroup.controls.departureDate.valid ||
      !this.reservationFormGroup.controls.stayTime.valid
    ) {
      this.snackBar.open(this.translate.instant('HotelReservations.CheckYourDetails'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    // if all input fields are valid except list of reservations show this confirm dialog
    if (!this.reservationFormGroup.controls.reservations.valid) {
      // Loop through all form controls and log the invalid ones
      // for (const controlName in this.reservationFormGroup.controls) {
      //   const control = this.reservationFormGroup.controls[controlName];

      //   if (control.invalid) {
      //     console.log(`❌ ${controlName} is invalid`);
      //     console.log(`   ➡ Current Value:`, control.value);

      //     // Check and log specific validation errors
      //     if (control.errors) {
      //       Object.keys(control.errors).forEach(errorKey => {
      //         console.log(`   🚨 Error: ${errorKey} -`, control.errors[errorKey]);
      //       });
      //     }

      //     // If it's a FormArray (like reservations), check its children
      //     if (control instanceof FormArray) {
      //       console.log(`   🔎 Inspecting FormArray: ${controlName}`);
      //       control.controls.forEach((group, index) => {
      //         if (group.invalid) {
      //           console.log(`   ❌ reservations[${index}] is invalid`);
      //           console.log(`      ➡ Current Value:`, group.value);

      //           if (group.errors) {
      //             Object.keys(group.errors).forEach(errorKey => {
      //               console.log(`      🚨 Error: ${errorKey} -`, group.errors[errorKey]);
      //             });
      //           }

      //           // Check each FormGroup inside the FormArray
      //           if (group instanceof FormGroup) {
      //             Object.keys(group.controls).forEach(childName => {
      //               const childControl = group.get(childName);
      //               if (childControl && childControl.invalid) {
      //                 console.log(`      ❌ Field '${childName}' inside reservations[${index}] is invalid`);
      //                 console.log(`         ➡ Current Value:`, childControl.value);
      //                 if (childControl.errors) {
      //                   Object.keys(childControl.errors).forEach(errorKey => {
      //                     console.log(`         🚨 Error: ${errorKey} -`, childControl.errors[errorKey]);
      //                   });
      //                 }
      //               }
      //             });
      //           }
      //         }
      //       });
      //     }
      //   } else {
      //     console.log(`✅ ${controlName} is valid`);
      //   }
      // }

      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.title = this.translate.instant('HotelReservations.NotAllReservationsFulfilled');
      modalRef.componentInstance.message = this.translate.instant('HotelReservations.DaysWithoutReservation');
      modalRef.componentInstance.showInfo = false;
      modalRef.componentInstance.buttonText = this.translate.instant('HotelReservations.Yes');
      modalRef.result.then(
        result => {
          if (result === 'ok') {
            this.processReservations();
          }
        },
        () => {}
      );
    }
  }

  searchFreeTables(index: number, previousTables: any = []) {
    const selectedRestaurantId = this.reservationForm.value[index].restaurant;
    const time = this.reservationForm.value[index].time;
    if (selectedRestaurantId && time) {
      this.updateFormGroupValue('reservations', { tableLoader: true }, index);
      if (this.reservationForm.value[index].table) {
        this.reservationForm.controls[index].get('table').patchValue(null, { emitEvent: false });
        this.updateFormGroupValue('reservations', { listOfTables: [] }, index);
        this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
      }
      const data = this.reservationFormGroup.value;
      const res = this.reservationForm.value[index];

      this.subscriptions.push(
        this.reservationService
          .checkFreeTables(
            res.date,
            time,
            data.numberOfPersons,
            data.stayTime.value,
            true,
            true,
            '',
            selectedRestaurantId
          )
          .subscribe(response => {
            const selectedRestaurant = response[0];
            this.fillTableArrayWithAvailableTables(selectedRestaurant, index, previousTables);
          })
      );
    }
  }

  fillTableArrayWithAvailableTables(selectedRestaurant: any, index: number, previousTables: any): void {
    const tables: any[] = [];
    const onlyTables: any[] = [];
    if (selectedRestaurant && selectedRestaurant.tables) {
      for (let i = 0; i < selectedRestaurant.tables.length; i++) {
        const element = selectedRestaurant.tables[i];
        if (!element.isRoom) {
          let isNotAvailable = false;
          let msg = this.translate.instant('HotelReservations.TableIsFree');

          if (element.isFree === false) {
            isNotAvailable = true;
            msg = this.translate.instant('HotelReservations.TableIsOccupied');
          } else if (parseInt(this.reservationFormGroup.get('numberOfPersons').value) > element.seats) {
            isNotAvailable = true;
            msg = `Max ${element.seats} ${this.translate.instant('HotelReservations.Persons')}`;
          }
          if (!element.isCombined) {
            tables.push({ table: element, isNotAvailable, msg });
            onlyTables.push(element);
          }
        }
      }
    }
    this.updateFormGroupValue('reservations', { listOfTables: tables }, index);
    if (previousTables && previousTables.length > 0 && tables.length > 0) {
      const availableTables = onlyTables.filter(
        (element: any) =>
          element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats
      );
      // Check if all previously selected tables exist in available tables
      const allTablesExist = previousTables.every((tableId: any) =>
        availableTables.some((item: any) => item.id === tableId)
      );

      if (!allTablesExist) {
        this.updateFormGroupValue(
          'reservations',
          { errorMessage: `${this.translate.instant('HotelReservations.TablesNotFree')}` },
          index
        );
      } else {
        setTimeout(() => {
          this.updateFormGroupValue('reservations', { table: previousTables }, index);
        }, 1000); // 1 seconds delay
        this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
      }
    }
    this.updateFormGroupValue('reservations', { tableLoader: false }, index);
  }

  onNumberOfPersonsChange() {
    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const element = this.reservationForm.value[i];
      if (element.restaurant) {
        this.searchFreeTables(i);
      }
    }
  }

  updateFormGroupValue(formGroupName: string, value: any, index: number): void {
    (<FormArray>this.reservationFormGroup.controls[formGroupName]).at(index).patchValue(value, { emitEvent: false });
  }

  processReservations(): void {
    const reservationFormData = this.reservationFormGroup.value;
    const reservationArray = reservationFormData.reservations;
    const reservations = [];
    for (let i = 0; i < reservationArray.length; i++) {
      const tempObject: any = {};
      const element = reservationArray[i];
      // if (element.date && element.restaurant && element.table) {
      if (element.date && element.restaurant && element.time) {
        tempObject.reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time + ':00:00';
        tempObject.room = element.restaurant;
        tempObject.table = element.table;
        reservations.push(tempObject);
      }
    }

    if (reservations.length === 0) {
      this.snackBar.open(`${this.translate.instant('HotelReservations.MinOneReservation')}`, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const reservation = {
      name: reservationFormData.name,
      roomNumber: reservationFormData.roomNumber,
      reservationNumber: reservationFormData.reservationNumber,
      arrivalDate: reservationFormData.arrivalDate,
      departureDate: reservationFormData.departureDate,
      selectedTags: reservationFormData.selectedTags,
      numberOfPersons: reservationFormData.numberOfPersons,
      numberOfKids: reservationFormData.numberOfKids,
      numberOfHighChairs: reservationFormData.numberOfHighChairs,
      incompatibilities: reservationFormData.incompatibilities,
      notes: reservationFormData.notes,
      reservations: reservations,
      stayTime: reservationFormData.stayTime.value,
      firstName: reservationFormData.firstName,
      email: reservationFormData.email,
      phone: reservationFormData.phone,
      board: reservationFormData.board,
      groupTags: reservationFormData.groupTags,
      hotelSystem: reservationFormData.hotelSystem ? reservationFormData.hotelSystem : null,
      guestNotes: reservationFormData.guestNotes ? reservationFormData.guestNotes : null,
      hotelResId: reservationFormData.hotelResId ? reservationFormData.hotelResId : null,
      createdAt: new Date()
    };
    this.submitObservable = this.subscriptions.push(
      this.reservationService.addMultipleReservations(reservation).subscribe(
        (res: any) => {
          if (res) {
            this.snackBar.open(`${this.translate.instant('HotelReservations.BookingSuccess')}`, '', {
              duration: 3000,
              panelClass: ['snackbar-success']
            });
            const foundPlaceRes = this.hotelReservations.find(
              (item: any) => item.reservationNo === reservationFormData.reservationNumber
            );
            if (foundPlaceRes) {
              foundPlaceRes.placed = true;
              this.hotelReservations = [...this.hotelReservations];
            } else {
              console.warn(`Reservation with number ${reservationFormData.reservationNumber} not found.`);
            }
            this.initForm();
          }
        },
        err => {
          if (err.status === 422) {
            this.snackBar.open(err.error.msg, '', {
              duration: 3000,
              panelClass: ['snackbar-error']
            });
          }
        }
      )
    );
  }

  initForm(): void {
    this.reservationFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      roomNumber: [null, Validators.required],
      reservationNumber: ['', Validators.required],
      arrivalDate: [null, Validators.required],
      departureDate: [null, Validators.required],
      stayTime: new FormControl(null, Validators.required),
      selectedTags: [[]],
      numberOfPersons: [1, Validators.required],
      numberOfKids: [0, Validators.required],
      numberOfHighChairs: [0, Validators.required],
      incompatibilities: [null],
      notes: [null],
      reservations: this.formBuilder.array([]),
      board: [null, Validators.required],
      firstName: [null],
      email: [null],
      phone: [null],
      groupTags: [null],
      hotelSystem: [null],
      guestNotes: [null],
      hotelResId: [null]
    });
    setTimeout(() => {
      this.reservationFormGroup.controls['stayTime'].setValue(this.stayTimes[0]);
    }, 500);
  }

  get reservationForm() {
    return <FormArray>this.reservationFormGroup.get('reservations');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // Function to validate To and From date
  validateDateRange() {
    this.showErrorMessage = false;
    if (moment(this.dates.from).isAfter(this.dates.to)) {
      this.showErrorMessage = true;
    }
  }

  setDates(type: string) {
    if (type === 'thisWeek') {
      const date = moment();
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    } else if (type === 'nextWeek') {
      const date = moment().add(1, 'week');
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    } else if (type === 'next2Weeks') {
      const date = moment().add(1, 'week');
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from)
        .add(1, 'week')
        .endOf('week');
    } else if (type === 'nextMonth') {
      const date = moment().add(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
    } else if (type === 'next2Months') {
      const date = moment().add(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from)
        .add(1, 'month')
        .endOf('month');
    } else if (type === 'next6Months') {
      const date = moment().add(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from)
        .add(5, 'months')
        .endOf('month');
    }
  }

  getHotelReservationOracle() {
    this.fetchingStartedHotelRes();
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationsOracle(this.dates.from, this.dates.to).subscribe(
        res => {
          if (res) {
            this.hotelReservations = res.map((item: any) => {
              let packagesCode = '';
              if (item.packages && item.packages[0]) {
                packagesCode = item.packages && item.packages[0] ? item.packages[0].packageCode : '';
                console.log(
                  'packages',
                  packagesCode,
                  item.packages[0].packageHeaderType.primaryDetails.description,
                  item.packages
                );
              }
              let hotelReservation: any = {};
              hotelReservation.reservationNo = item.reservationIdList[0].id;
              hotelReservation.arrivalDate = item.roomStay.arrivalDate;
              hotelReservation.departureDate = item.roomStay.departureDate;
              hotelReservation.guestCount = item.roomStay.adultCount + item.roomStay.childCount;
              // hotelReservation.guestCount = item.roomStay.adultCount;
              hotelReservation.childCount = item.roomStay.childCount;
              hotelReservation.roomNo = item.roomStay.roomId;
              hotelReservation.guestInfo = {
                name: item.reservationGuest.surname,
                firstName: item.reservationGuest.givenName,
                email: item.reservationGuest.email,
                phoneNo: item.reservationGuest.phoneNumber,
                guestNotes: ''
              };
              // hotelReservation.board = packagesCode;
              hotelReservation.placed = this.allHotelReservations.includes(hotelReservation.reservationNo.toString());
              return hotelReservation;
            });
          }
          console.log('hello1', this.hotelReservations);
          this.setHotelPaginationData();
        },
        err => {
          this.setHotelPaginationData();
          const errMsg =
            err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  getHotelReservationASA() {
    this.fetchingStartedHotelRes();
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationsASA(this.dates.from, this.dates.to).subscribe(
        res => {
          if (res) {
            console.log('hello', res);
            for (let item of res) {
              if (!item.type || item.type != 'contingent') {
                let hotelReservation: any = {};
                hotelReservation.reservationNo = item.number;
                const roomNumbers = item.roomReservations.roomReservation.map(
                  (reservation: any) => reservation.room.number
                );

                const roomNumbersString = roomNumbers.join(',');
                const roomReservations = item.roomReservations.roomReservation[0];
                const boardCode = roomReservations.boardCode;
                hotelReservation.arrivalDate = roomReservations.arrival;
                hotelReservation.departureDate = roomReservations.departure;

                hotelReservation.guestCount =
                  (roomReservations.adults ? roomReservations.adults : 0) +
                  (roomReservations.children ? roomReservations.children : 0);
                hotelReservation.childCount = roomReservations.children ? roomReservations.children : 0;

                hotelReservation.roomNo = roomNumbersString;
                const annotations = [
                  roomReservations.annotation,
                  roomReservations.diningRoomAnnotation,
                  roomReservations.kitchenAnnotation,
                  roomReservations.diningAnnotation
                ]
                  .filter(Boolean)
                  .join('\n');

                if (annotations) {
                  hotelReservation.notes = annotations;
                }
                const guestName = roomReservations.guestName ? roomReservations.guestName.split(' ') : '';

                let guestAnnotations = '';
                if (
                  roomReservations.roomGuests &&
                  roomReservations.roomGuests.roomGuest &&
                  roomReservations.roomGuests.roomGuest.length > 0
                ) {
                  guestAnnotations = roomReservations.roomGuests.roomGuest
                    .map((guest: any) => [
                      guest.annotation,
                      guest.diningRoomAnnotation,
                      guest.kitchenAnnotation,
                      guest.diningAnnotation
                    ])
                    .flat()
                    .filter(Boolean)
                    .join('\n');
                }
                hotelReservation.guestInfo = {
                  name: item.guest
                    ? item.guest.lastName
                    : guestName
                    ? guestName[0]
                    : `${this.translate.instant('HotelReservations.NoName')}`,
                  firstName: item.guest ? item.guest.firstName : guestName ? guestName[1] : '',
                  email: item.guest ? item.guest.email : '',
                  phoneNo: item.guest ? item.guest.telephone : '',
                  guestNotes: guestAnnotations
                };

                if (boardCode == 'BB' || boardCode == 'HB' || boardCode == 'FB') {
                  hotelReservation.board = this.replaceBoardcode(boardCode);
                }

                hotelReservation.reservationInfo = {
                  BoardCode: boardCode ? boardCode : '',
                  RatePlanCode: roomReservations.ratePlanCode ? roomReservations.ratePlanCode : ''
                };

                hotelReservation.placed = this.allHotelReservations.includes(hotelReservation.reservationNo.toString());
                hotelReservation.hotelResId = item.id;
                this.hotelReservations.push(hotelReservation);
              }
            }
          }
          this.hotelReservations = [...this.hotelReservations];
          console.log('hello1', this.hotelReservations);
          this.setHotelPaginationData();
        },
        err => {
          this.setHotelPaginationData();
          const errMsg =
            err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  getHotelReservationIbelsa() {
    this.fetchingStartedHotelRes();
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationIbelsa(this.dates.from, this.dates.to).subscribe(
        res => {
          if (res) {
            console.log('hello', res);
            for (let item of res) {
              if (item.room_stays && item.room_stays.length) {
                let hotelReservation: any = {};
                hotelReservation.reservationNo = item.id;
                const roomNumbers = item.room_stays.map((room: any) => room.room_name);
                const roomNumbersString = roomNumbers.join(',');
                hotelReservation.arrivalDate = item.starts_at;
                hotelReservation.departureDate = item.ends_at;
                hotelReservation.guestCount = item.person_count;
                hotelReservation.roomNo = roomNumbersString;
                hotelReservation.notes = item.invoice_items && item.invoice_items[0] ? item.invoice_items[0].name : '';
                hotelReservation.guestInfo = {
                  name: item.guest ? item.guest.lastname : `${this.translate.instant('HotelReservations.NoName')}`,
                  firstName: item.guest ? item.guest.firstname : '',
                  email: item.guest ? item.guest.email : '',
                  phoneNo: item.guest ? item.guest.mobilephone : '',
                  guestNotes: ''
                };
                // hotelReservation.board = boardCode;
                hotelReservation.placed = this.allHotelReservations.includes(hotelReservation.reservationNo.toString());
                this.hotelReservations.push(hotelReservation);
              }
            }
          }
          this.hotelReservations = [...this.hotelReservations];
          console.log('hello1', this.hotelReservations);
          this.setHotelPaginationData();
        },
        err => {
          this.setHotelPaginationData();
          const errMsg =
            err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  getHotelReservationCasablanca() {
    this.fetchingStartedHotelRes();
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationCasablanca(this.dates.from, this.dates.to).subscribe(
        res => {
          if (res) {
            console.log('hello', res);
            for (let item of res) {
              if (item.$.ResStatus === 'Reserved') {
                let hotelReservation: any = {};

                // Extract item number
                const uniqueID = item.UniqueID.find((id: any) => id.$.Type === '14');
                hotelReservation.reservationNo = uniqueID ? uniqueID.$.ID : '';
                // hotelReservation.reservationNo = item.ResGlobalInfo && item.ResGlobalInfo.HotelReservationIDs ?  item.ResGlobalInfo.HotelReservationIDs.HotelReservationID[0].$.ResID_Type: '';

                hotelReservation.notes =
                  item.ResGlobalInfo &&
                  item.ResGlobalInfo.Comments &&
                  item.ResGlobalInfo.Comments.Comment &&
                  item.ResGlobalInfo.Comments.Comment.Text
                    ? item.ResGlobalInfo.Comments.Comment.Text.replace(/\r\n/g, '\n')
                    : '';

                // Extract room numbers
                const roomStays = Array.isArray(item.RoomStays.RoomStay)
                  ? item.RoomStays.RoomStay
                  : [item.RoomStays.RoomStay];
                const roomNumbers = roomStays.map((roomStay: any) => roomStay.RoomTypes.RoomType.$.RoomID);
                hotelReservation.roomNo = roomNumbers.join(',');

                // Extract dates
                const timeSpan = roomStays[0].TimeSpan.$;
                hotelReservation.arrivalDate = timeSpan.Start;
                hotelReservation.departureDate = timeSpan.End;

                // Extract guest count
                // const guestCount =  roomStays[0].GuestCounts && roomStays[0].GuestCounts.GuestCount && roomStays[0].GuestCounts.GuestCount.$ ? roomStays[0].GuestCounts.GuestCount.$.Count : 0;
                // hotelReservation.guestCount = guestCount;

                let guestCount = 0;

                if (roomStays[0].GuestCounts && roomStays[0].GuestCounts.GuestCount) {
                  const guestData = roomStays[0].GuestCounts.GuestCount;
                  if (Array.isArray(guestData)) {
                    guestCount = guestData.reduce(
                      (sum, guest) => sum + (guest.$.Count ? parseInt(guest.$.Count, 10) : 0),
                      0
                    );
                  } else if (guestData.$ && guestData.$.Count) {
                    guestCount = parseInt(guestData.$.Count, 10);
                  }
                }

                hotelReservation.guestCount = guestCount;

                // Extract guest information
                if (item.ResGlobalInfo && item.ResGlobalInfo.Profiles) {
                  const guestProfile = item.ResGlobalInfo.Profiles.ProfileInfo.Profile.Customer;
                  hotelReservation.guestInfo = {
                    name: guestProfile.PersonName.Surname || `${this.translate.instant('HotelReservations.NoName')}`,
                    firstName: guestProfile.PersonName.NamePrefix || '',
                    email: guestProfile.Email ? guestProfile.Email._ : '',
                    phoneNo:
                      guestProfile.Telephone && guestProfile.Telephone[0] && guestProfile.Telephone[0].$
                        ? guestProfile.Telephone[0].$.PhoneNumber
                        : '',
                    guestNotes: ''
                  };
                }
                let ratePlanCode = '';
                if (roomStays[0].RoomRates && roomStays[0].RoomRates.RoomRate) {
                  const roomRate = roomStays[0].RoomRates.RoomRate;
                  if (Array.isArray(roomRate)) {
                    ratePlanCode = roomRate[0].$.RatePlanCode;
                  } else if (roomRate.$.RatePlanCode) {
                    ratePlanCode = roomRate.$.RatePlanCode;
                  }
                }
                hotelReservation.reservationInfo = {
                  RatePlanCode: ratePlanCode ? ratePlanCode : ''
                };

                // Check if item is already placed
                hotelReservation.placed = this.allHotelReservations.includes(hotelReservation.reservationNo.toString());

                // Add to reservations array
                this.hotelReservations.push(hotelReservation);
              }
            }
          }
          this.hotelReservations = [...this.hotelReservations];
          console.log('hello1', this.hotelReservations);
          this.setHotelPaginationData();
        },
        err => {
          this.setHotelPaginationData();
          const errMsg =
            err && err.error.msg ? err.error.msg : `${this.translate.instant('HotelReservations.SomethingWentWrong')}`;
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  placeGuest(reservation: any) {
    this.callPlaced = true;
    this.reservationFormGroup.get('name').setValue(reservation.guestInfo.name);
    this.reservationFormGroup.get('roomNumber').setValue(reservation.roomNo);
    this.reservationFormGroup.get('reservationNumber').setValue(reservation.reservationNo);
    this.reservationFormGroup.get('arrivalDate').setValue(moment(reservation.arrivalDate));
    this.reservationFormGroup.get('departureDate').setValue(moment(reservation.departureDate));
    this.reservationFormGroup.get('numberOfPersons').setValue(reservation.guestCount);
    this.reservationFormGroup.get('numberOfKids').setValue(reservation.childCount ? reservation.childCount : 0);
    this.reservationFormGroup.get('board').setValue(reservation.board);
    this.reservationFormGroup.get('notes').setValue(reservation.notes);
    this.reservationFormGroup.get('firstName').setValue(reservation.guestInfo.firstName);
    this.reservationFormGroup.get('email').setValue(reservation.guestInfo.email);
    this.reservationFormGroup.get('phone').setValue(reservation.guestInfo.phone);
    this.reservationFormGroup.get('guestNotes').setValue(reservation.guestInfo.guestNotes);
    this.reservationFormGroup.get('hotelResId').setValue(reservation.hotelResId);
    this.reservationFormGroup.get('groupTags').setValue(reservation.reservationInfo.RatePlanCode);
    (this.reservationForm as FormArray).clear();
    this.checkIsSelectedTimeIntervalValid();
  }

  callCopyAllItems(i: any, mealType: string) {
    this.copyAllItems = true;

    // Find the first row of the given mealType
    // const firstElement = this.reservationForm.value.find((item: any) => item.mealType === mealType);
    // if (!firstElement) return; // If no such row, exit

    const firstElement = this.reservationForm.value[i];

    // Iterate through all rows and update only matching mealType rows
    this.reservationForm.value.forEach((element: any, index: any) => {
      if (element.mealType === mealType && element.resIndex && element.resIndex != 1) {
        this.updateFormGroupValue('reservations', { listOfTimes: firstElement.listOfTimes }, index);
        this.updateFormGroupValue('reservations', { listOfRestaurants: firstElement.listOfRestaurants }, index);
        this.updateFormGroupValue('reservations', { listOfTables: firstElement.listOfTables }, index);
        this.updateFormGroupValue('reservations', { time: firstElement.time }, index);
        this.updateFormGroupValue('reservations', { restaurant: firstElement.restaurant }, index);
        (async () => {
          if (firstElement.table && firstElement.table.length > 0) {
            this.updateFormGroupValue('reservations', { tableLoader: true }, index);

            // Check availability for all selected tables
            const availabilityChecks = await Promise.all(
              firstElement.table.map((tableId: any) => this.checkTableAvailability(firstElement.time, tableId, index))
            );

            // If any table is not available, show an error
            const isAnyTableUnavailable = availabilityChecks.includes(false);

            if (isAnyTableUnavailable) {
              this.updateFormGroupValue(
                'reservations',
                { errorMessage: 'One or more selected tables are not free' },
                index
              );
            } else {
              this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
            }

            this.updateFormGroupValue('reservations', { table: firstElement.table }, index);
            this.updateFormGroupValue('reservations', { tableLoader: false }, index);
          }
        })();
      }
    });
  }

  checkTableAvailability(time: any, table: any, index: any): Promise<boolean> {
    return new Promise(resolve => {
      const selectedRestaurantId = this.reservationForm.value[index].restaurant;
      const data = this.reservationFormGroup.value;
      const res = this.reservationForm.value[index];

      this.subscriptions.push(
        this.reservationService
          .checkFreeTables(
            res.date,
            time,
            data.numberOfPersons,
            data.stayTime.value,
            true,
            true,
            '',
            selectedRestaurantId
          )
          .subscribe(response => {
            const selectedRestaurant = response[0];
            const availableTables = selectedRestaurant.tables.filter(
              (element: any) =>
                element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats
            );
            const tableExist = availableTables.some((item: any) => item.id === table);
            resolve(tableExist);
          })
      );
    });
  }

  getTimes() {
    this.defaultTime = moment().format('HH:mm');
    this.times = [];
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        isClosed: true
      };
      this.times.push(timeObj);

      console.log('get times');
      const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
      const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
      const dateObjNext = new Date();
      dateObjNext.setHours(hhNext);
      dateObjNext.setMinutes(mmNext);

      const dateObj = new Date();
      dateObj.setHours(hh);
      dateObj.setMinutes(mm);

      const defaultTimeDateObj = new Date();
      const defaultTimeSplit = this.defaultTime.split(':');
      defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
      defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);

      if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
        const defaultTimeObj = {
          time: this.defaultTime,
          isClosed: false
        };
      }

      tStart = tStart + interval;
    }
    // Check opening hours and append Closed label to times which are closed
    this.clientService
      .getOpeningHourOfDay(moment(this.reservationFormGroup.value.date).format('YYYY-MM-DD'))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(async (openingHours: any) => {
        if (openingHours.length) {
          // Loop trough all entries to check time
          await openingHours.reduce(
            (prev: any, oh: any) =>
              prev.then(async () => {
                this.times = await this._checkTimesWithOpeningHours(oh.tFrom, oh.tTo, oh.closed);
              }),
            Promise.resolve()
          );
        } else {
          // No opening hours for this day, so its closed
          const newTimes = await this.times.map((time: any) => {
            time.isClosed = true;
            return time;
          });
          this.times = [...newTimes];
        }
      });
  }

  private _checkTimesWithOpeningHours(tFrom: string, tTo: string, closed: boolean = false) {
    const from = moment(tFrom, 'HH:mm');
    const to = moment(tTo, 'HH:mm');

    return Promise.all(
      this.times.map((t: any) => {
        if (t.isClosed && !closed) {
          const time = moment(t.time, 'HH:mm');
          if (from.isBefore(to)) {
            t.isClosed = !(
              time.isBetween(from, to) ||
              time.format('HH:mm') === from.format('HH:mm') ||
              time.format('HH:mm') === to.format('HH:mm')
            );
          } else {
            t.isClosed = time.isBetween(to, from);
          }
          return t;
        } else if (!t.isClosed && closed) {
          const time = moment(t.time, 'HH:mm');
          if (from.isBefore(to)) {
            t.isClosed =
              time.isBetween(from, to) ||
              time.format('HH:mm') === from.format('HH:mm') ||
              time.format('HH:mm') === to.format('HH:mm');
          } else {
            t.isClosed = !time.isBetween(to, from);
          }
          return t;
        } else {
          // Time oh was alredy set
          return t;
        }
      })
    );
  }

  changeBoardOption() {
    if (this.reservationFormGroup.value.arrivalDate && this.reservationFormGroup.value.departureDate) {
      // Clear existing reservations and insert in correct order
      (this.reservationForm as FormArray).clear();
      this.checkIsSelectedTimeIntervalValid();
    }
  }
  checkIsSelectedTimeIntervalValid(): void {
    let reservations = this.reservationForm as FormArray;
    let arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
    arrivalDate.setHours(0, 0, 0, 0);
    let departureDate = new Date(this.reservationFormGroup.value.departureDate);
    departureDate.setHours(0, 0, 0, 0);
    const board = this.reservationFormGroup.value.board;

    if (reservations.length > 0) {
      if (arrivalDate.getTime() > this.previousArrivalDate.getTime()) {
        this.removeReservationsBefore(arrivalDate);
      } else if (arrivalDate.getTime() < this.previousArrivalDate.getTime()) {
        this.addElementsToReservations(arrivalDate, subDays(this.previousArrivalDate, 1), board);
      }

      if (departureDate.getTime() < this.previousDepartureDate.getTime()) {
        this.removeReservationsAfter(departureDate);
      } else if (departureDate.getTime() > this.previousDepartureDate.getTime()) {
        this.addElementsToReservations(addDays(this.previousDepartureDate, 1), departureDate, board);
      }
    } else {
      if (arrivalDate && departureDate && departureDate.getTime() > arrivalDate.getTime()) {
        this.addElementsToReservations(arrivalDate, departureDate, board);
      }
    }
    this.previousArrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
    this.previousArrivalDate.setHours(0, 0, 0, 0);
    this.previousDepartureDate = new Date(this.reservationFormGroup.value.departureDate);
    this.previousDepartureDate.setHours(0, 0, 0, 0);
  }

  /**
   * Adds reservations for **future dates only**, in correct order:
   * 1. All breakfast rows for all future dates
   * 2. All lunch rows for all future dates (if full board)
   * 3. All dinner rows for all future dates
   */
  addElementsToReservations(startDate: Date, endDate: Date, board: string) {
    let currentDate = new Date(startDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dinnerReservations: FormGroup[] = [];
    const lunchReservations: FormGroup[] = [];
    const breakfastReservations: FormGroup[] = [];

    while (currentDate.getTime() <= endDate.getTime()) {
      if (currentDate.getTime() >= today.getTime()) {
        // Only add future dates
        if (board === 'HP' || board === 'VP') {
          dinnerReservations.push(this.addNewReservationGroup(new Date(currentDate), 'dinner'));
        }
        if (board === 'VP') {
          lunchReservations.push(this.addNewReservationGroup(new Date(currentDate), 'lunch'));
        }
        if (board === 'FR' || board === 'HP' || board === 'VP') {
          breakfastReservations.push(this.addNewReservationGroup(new Date(currentDate), 'breakfast'));
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // this.insertReservationsByMealType([...dinnerReservations, ...lunchReservations, ...breakfastReservations]);

    // [...dinnerReservations, ...lunchReservations, ...breakfastReservations].forEach(res =>
    //   (this.reservationForm as FormArray).push(res)
    // );

    const existingReservationsFormControl = (this.reservationForm as FormArray).controls;
    const existingReservations = existingReservationsFormControl.map(control => control as FormGroup);
    const allReservations: any = [
      ...existingReservations,
      ...dinnerReservations,
      ...lunchReservations,
      ...breakfastReservations
    ];
    this.sortReservationAndFilterReservations(allReservations);
  }

  sortReservationAndFilterReservations(allReservations: any) {
    allReservations.sort((a: any, b: any) => {
      const mealOrderA = this.mealTypeOrder[a.value.mealType] || 99;
      const mealOrderB = this.mealTypeOrder[b.value.mealType] || 99;

      if (mealOrderA !== mealOrderB) {
        return mealOrderA - mealOrderB;
      }
      const dateA = new Date(a.value.date).getTime();
      const dateB = new Date(b.value.date).getTime();

      return dateA - dateB;
    });

    (this.reservationForm as FormArray).clear();

    allReservations.forEach((res: any) => {
      const mealType = res.get('mealType').value;
      const date = res.get('date').value;
      date.setHours(0, 0, 0, 0);
      const arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
      arrivalDate.setHours(0, 0, 0, 0);
      const departureDate = new Date(this.reservationFormGroup.value.departureDate);
      departureDate.setHours(0, 0, 0, 0);

      let exempt = false;
      if (date.getTime() == arrivalDate.getTime()) {
        // console.log('firstday', date);
        exempt = mealType !== 'dinner';
      }
      if (date.getTime() == departureDate.getTime()) {
        // console.log('lastday', date);
        exempt = mealType !== 'breakfast';
      }
      res.get('exempt').setValue(exempt);
      (this.reservationForm as FormArray).push(res);
    });
    this.updateFirstEditableForMealType();
  }

  // To find the last index of a given mealType
  findLastMealTypeIndex(mealType: string) {
    const existingReservations = this.reservationForm.value;
    let lastIndex = -1;
    existingReservations.forEach((res: any, index: any) => {
      if (res.mealType === mealType) {
        lastIndex = index;
      }
    });
    return lastIndex;
  }

  // Function to insert reservations after the last occurrence of their type
  insertReservationsByMealType(newReservations: FormGroup[]) {
    newReservations.forEach(newRes => {
      const mealType = newRes.get('mealType').value;
      const date = newRes.get('date').value;
      date.setHours(0, 0, 0, 0);
      const arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
      arrivalDate.setHours(0, 0, 0, 0);
      const departureDate = new Date(this.reservationFormGroup.value.departureDate);
      departureDate.setHours(0, 0, 0, 0);

      if (date.getTime() == arrivalDate.getTime()) {
        console.log('firstday', date);
        if (mealType !== 'dinner') {
          newRes.get('exempt').setValue(true);
        }
      }
      if (date.getTime() == departureDate.getTime()) {
        console.log('lastday', date);
        if (mealType !== 'breakfast') {
          newRes.get('exempt').setValue(true);
        }
      }
      const lastIndex = this.findLastMealTypeIndex(mealType);

      if (lastIndex === -1) {
        (this.reservationForm as FormArray).push(newRes);
      } else {
        (this.reservationForm as FormArray).insert(lastIndex + 1, newRes);
      }
    });
  }

  /**
   * Removes reservations before a given date.
   */
  removeReservationsBefore(date: Date) {
    const reservations = this.reservationForm as FormArray;
    for (let i = reservations.length - 1; i >= 0; i--) {
      if (new Date(reservations.at(i).value.date).getTime() < date.getTime()) {
        reservations.removeAt(i);
        this.updateFirstEditableForMealType();
      }
    }
  }

  /**
   * Removes reservations after a given date.
   */
  removeReservationsAfter(date: Date) {
    const reservations = this.reservationForm as FormArray;
    for (let i = reservations.length - 1; i >= 0; i--) {
      if (new Date(reservations.at(i).value.date).getTime() > date.getTime()) {
        reservations.removeAt(i);
        this.updateFirstEditableForMealType();
      }
    }
  }

  removeReservationAtIndex(i: any) {
    const reservations = this.reservationForm as FormArray;
    reservations.removeAt(i);
    this.updateFirstEditableForMealType();
  }

  // isFirstRowEmpty(mealType: string): boolean {
  //   const firstRow = this.reservationForm.value.find((item: any) => item.mealType === mealType);
  //   return !firstRow.table;
  // }

  // isFirstRowOfMealType(mealType: string, index: number): boolean {
  //   return this.reservationForm.value.findIndex((item: any) => item.mealType === mealType) === index;
  // }

  updateFirstEditableForMealType() {
    this.breakfastCounter = 1;
    this.lunchCounter = 1;
    this.dinnerCounter = 1;
    let defaultTime = null;

    const processedMealTypes: string[] = [];

    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const row = this.reservationForm.value[i];
      if (!row.exempt) {
        this.updateFormGroupValue('reservations', { resIndex: this.getMealTypeCounter(row.mealType) }, i);

        // If the mealType is not in the processed array, it's the first occurrence
        if (!processedMealTypes.includes(row.mealType)) {
          this.updateFormGroupValue('reservations', { isEditable: true }, i);
          processedMealTypes.push(row.mealType); // Add the mealType to the processed list
          if (!row.time) {
            if (row.mealType === 'breakfast') {
              const foundTime = this.times.find(t => t.time === '08:00');
              defaultTime = foundTime ? foundTime.time : null;
            } else if (row.mealType === 'lunch') {
              const foundTime = this.times.find(t => t.time === '13:00');
              defaultTime = foundTime ? foundTime.time : null;
            } else if (row.mealType === 'dinner') {
              const foundTime = this.times.find(t => t.time === '19:00');
              defaultTime = foundTime ? foundTime.time : null;
            }
            this.updateFormGroupValue('reservations', { time: defaultTime }, i);
          }
        } else {
          this.updateFormGroupValue('reservations', { isEditable: false }, i);
        }
      }
    }
  }
  getMealTypeCounter(mealType: string): number {
    if (mealType === 'breakfast') {
      return this.breakfastCounter++;
    } else if (mealType === 'lunch') {
      return this.lunchCounter++;
    } else if (mealType === 'dinner') {
      return this.dinnerCounter++;
    }
    return 0;
  }

  editReservation(i: any) {
    this.updateFormGroupValue('reservations', { isEditable: true }, i);
    this.searchFreeTables(i);
  }

  replaceGuestReservation(index: any) {
    const element = this.reservationForm.value[index];
    const allDataList = {
      listOfRestaurants: element.listOfRestaurants,
      listOfTimes: element.listOfTimes
    };
    const reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time;
    this.reservationService
      .fetchExistingGuestReservation(reservedFor, this.reservationFormGroup.value.stayTime, element.table)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (reservation: any) => {
          if (reservation) {
            const modalRef = this.modalService.open(ReplaceGuestReservationComponent, {
              windowClass: 'onboarding-modal',
              size: 'lg'
            });
            modalRef.componentInstance.reservation = reservation;
            modalRef.componentInstance.allDataList = allDataList;
            modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
              this.reservationService.replaceGuestReservation(receivedEntry).then((response: any) => {
                this.searchFreeTables(index, element.table);
                const snackBarRef = this.snackBar.open(`${this.translate.instant('HotelReservations.Success')}`, 'Ok', {
                  duration: 3000,
                  panelClass: ['snackbar-success']
                });
                modalRef.close();
              });
            });
          }
        },
        err => {
          console.error('Error fetching reservation', err);
        }
      );
  }

  onTableChanged(index: any) {
    const element = this.reservationForm.value[index];
    // Find if any selected table is unavailable
    const isAnyTableUnavailable = element.table.some((tableId: any) => {
      const foundTable = element.listOfTables.find((item: any) => item.table.id === tableId);
      return foundTable.isNotAvailable;
    });

    if (isAnyTableUnavailable) {
      this.updateFormGroupValue('reservations', { errorMessage: 'One or more selected tables are not free' }, index);
    } else {
      this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
    }
  }

  replaceBoardcode(boardCode: any) {
    const replacements: any = {
      BB: 'FR',
      HB: 'HP',
      FB: 'VP'
    };
    return replacements[boardCode] || boardCode;
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  fetchColor(board: string): string {
    const meal = this.mealOptions.find(option => option.value === board);
    return meal ? meal.color : 'gray';
  }

  updatePagination() {
    this.hotelReservations.sort((a: any, b: any) => {
      return new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime();
    });

    this.totalPages = Math.ceil(this.hotelReservations.length / this.pageSize);

    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages || 1;
    }

    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;

    this.paginatedReservations = this.hotelReservations.slice(start, end).map((r: any) => ({ ...r, expanded: false }));

    if (this.searchTerm) {
      this.paginatedReservations = this.paginatedReservations.filter((item: any) => {
        if (item.guestInfo) {
          return (
            (item.guestInfo.firstName &&
              item.guestInfo.firstName.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (item.guestInfo.name && item.guestInfo.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
          );
        } else {
          return false;
        }
      });
    }
    console.log('Paginated:', this.paginatedReservations);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePagination();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePagination();
    }
  }

  setHotelPaginationData() {
    this.loadingHotelRes = false;
    this.updatePagination();
  }

  fetchingStartedHotelRes() {
    this.loadingHotelRes = true;
    this.hotelReservations = [];
    this.currentPage = 1;
    this.updatePagination();
    this.initForm();
    (this.reservationForm as FormArray).clear();
    this.callPlaced = false;
  }

  clearSearch() {
    this.searchTerm = '';
    this.updatePagination();
  }
}
