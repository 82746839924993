import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
@Component({
  selector: 'app-banner',
  templateUrl: './banner-invoice.component.html',
  styleUrls: ['./banner-invoice.component.scss']
})
export class BannerInvoiceComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  showBanner: boolean = true;
  pendingInvoices: number = 0;
  totalAmount: number = 0;

  constructor(
    private router: Router,
    private clientService: ClientService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.showBanner = false;
    this.getPendingInvoices();
  }

  getPendingInvoices() {
    this.clientService
      .getPendingInvoices()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.pendingCount > 0) {
          const lastClosed = localStorage.getItem('bannerClosedDate');
          const today = new Date().toISOString().split('T')[0];

          const todayDate = new Date();
          const sixtyDaysAgo = new Date();
          sixtyDaysAgo.setDate(todayDate.getDate() - 60);

          const hasOldInvoice = res.invoices.some((invoice: any) => {
            if (invoice.zahlungsziel) {
              return new Date(invoice.zahlungsziel) < todayDate;
            } else {
              return new Date(invoice.createdAt) <= sixtyDaysAgo;
            }
          });

          if (hasOldInvoice && lastClosed !== today) {
            this.showBanner = true;
          } else {
            this.showBanner = false;
          }

          this.pendingInvoices = res.pendingCount;
          this.totalAmount = res.totalAmount;
        } else {
          this.showBanner = false;
        }
      });
  }

  navigateToInvoices() {
    this.router.navigate([`client/${this.authService.activeClientId}/accounting/invoices`]);
  }

  closeBanner() {
    this.showBanner = false;
    localStorage.setItem('bannerClosedDate', new Date().toISOString().split('T')[0]);
  }
}
