import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, Constants, CredentialsService, resStatus } from '@app/core';
import { ReservationService } from '@app/reservation/reservation.service';
import { of, Subject } from 'rxjs';
import { subDays, addDays, isSameDay } from 'date-fns';
import moment from 'moment';
import { ThemeService } from '@app/shell/theme.service';
moment.locale('de');

@Component({
  selector: 'app-occupancy-index',
  templateUrl: './occupancy-index.component.html',
  styleUrls: ['./occupancy-index.component.scss']
})
export class OccupancyIndexComponent implements OnInit, OnDestroy {
  userClients: any[] = [];
  loadingReservationList: boolean;
  clientReservations: { [key: string]: { reservations: any[]; clientInfo: any; totalPeople?: number } } = {};
  reservations: any = [];
  currentDate: Date = new Date();
  activeTheme: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;
  clientRes: any;

  constructor(
    private reservationService: ReservationService,
    private authService: AuthenticationService,
    private themeService: ThemeService
  ) {
    this.themeService.activeTheme$.subscribe((theme: any) => {
      this.activeTheme = theme;
    });
  }

  async ngOnInit() {
    this.getUserClients();
  }

  getUserClients() {
    const clientsObservable = this.authService.getClients(false) || of({ clients: [] });

    clientsObservable.subscribe(
      res => {
        if (res && res.clients) {
          this.userClients = res.clients;
          this.loadReservations();
        } else {
          console.warn('No clients were found.');
        }
      },
      error => {
        console.error('Error retrieving the clients:', error);
      }
    );
  }

  async loadReservations() {
    for (const client of this.userClients) {
      await this.getAllReservations(client.id);
    }
  }

  async getAllReservations(clientId: string | number) {
    this.loadingReservationList = true;

    if (!this.clientReservations[clientId]) {
      this.clientReservations[clientId] = {
        reservations: [],
        clientInfo: this.userClients.find(client => client.id === clientId) || {},
        totalPeople: 0
      };
    }
    await this.reservationService
      .getAllReservationsBookByCustomClient(this.currentDate, this.currentDate, clientId)
      .toPromise()
      .then(async (data: any) => {
        if (data) {
          this.clientReservations[clientId].reservations = data.reservations;
          this.clientReservations[clientId].totalPeople = data.reservations.reduce(
            (sum: any, reserva: { peopleCount: any }) => sum + (reserva.peopleCount || 0),
            0
          );
          console.log(this.clientReservations[clientId].totalPeople);
          await new Promise<void>(resolve => {
            resolve();
          });
          this.loadingReservationList = false;
        }
      });
  }

  today() {
    this.currentDate = new Date();
    this.changeDate();
  }

  prevDay() {
    this.currentDate = subDays(this.currentDate, 1);
    this.changeDate();
  }

  nextDay() {
    this.currentDate = addDays(this.currentDate, 1);
    this.changeDate();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async changeDate() {
    for (const client of this.userClients) {
      this.clientReservations[client.id].reservations = [];
      await this.getAllReservations(client.id);
    }
  }

  isToday() {
    return isSameDay(new Date(), this.currentDate);
  }
}
