import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/add/operator/takeUntil';
import { Subject, Subscription } from 'rxjs';
import { ReservationService } from '@app/reservation/reservation.service';
import moment from 'moment';
moment.locale('de');
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-replace-guest-reservation',
  templateUrl: './replace-guest-reservation.component.html',
  styleUrls: ['./replace-guest-reservation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReplaceGuestReservationComponent implements OnInit, OnDestroy {
  @Input() public reservation: any;
  @Input() public allDataList: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  reservationFormGroup: FormGroup;
  private ngUnsubscribe: Subject<any> = new Subject();
  tableLoader: boolean = false;
  listOfTables: any = [];
  subscriptions: Subscription[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private reservationService: ReservationService,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {});
  }

  ngOnInit() {
    this.reservationFormGroup = this.formBuilder.group({
      restaurant: [this.reservation.roomId, Validators.required],
      time: [moment(this.reservation.reservedFor).format('HH:mm'), Validators.required],
      table: []
    });
    this.searchFreeTables();
  }

  updateReservation() {
    let reservation = this.reservationFormGroup.value;
    if (!this.reservationFormGroup.valid) {
      this.snackBar.open(this.translate.instant('HotelReservations.CheckYourDetails'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const reservedFor = moment(this.reservation.reservedFor).format('YYYY-MM-DD') + ' ' + reservation.time;
    const formValue = {
      reservedFor,
      roomId: reservation.restaurant,
      isTablePlan: reservation.table,
      id: this.reservation.id
    };
    this.passEntry.emit(formValue);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  searchFreeTables() {
    const selectedRestaurantId = this.reservationFormGroup.value.restaurant;
    const time = this.reservationFormGroup.value.time;
    if (selectedRestaurantId && time) {
      this.tableLoader = true;
      this.subscriptions.push(
        this.reservationService
          .checkFreeTables(
            moment(this.reservation.reservedFor).format('YYYY-MM-DD'),
            time,
            this.reservation.peopleCount,
            this.reservation.stayTime,
            true,
            true,
            this.reservation.id,
            selectedRestaurantId
          )
          .subscribe(response => {
            const selectedRestaurant = response[0];
            this.fillTableArrayWithAvailableTables(selectedRestaurant);
          })
      );
    }
  }

  fillTableArrayWithAvailableTables(selectedRestaurant: any): void {
    this.listOfTables = [];
    for (let i = 0; i < selectedRestaurant.tables.length; i++) {
      const element = selectedRestaurant.tables[i];

      let isNotAvailable = false;
      let msg = this.translate.instant('HotelReservations.TableIsFree');

      if (element.isFree === false) {
        isNotAvailable = true;
        msg = this.translate.instant('HotelReservations.TableIsOccupied');
      } else if (parseInt(this.reservation.peopleCount.value) > element.seats) {
        isNotAvailable = true;
        msg = `Max ${element.seats}  ${this.translate.instant('HotelReservations.Persons')}`;
      }
      if (!element.isCombined) {
        this.listOfTables.push({ table: element, isNotAvailable, msg });
      }
    }
    this.tableLoader = false;
    setTimeout(() => {
      this.reservationFormGroup.get('table').setValue(this.reservation.isTablePlan.split(',').map(Number));
    }, 1000); // 1 seconds delay
  }
}
