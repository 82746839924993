import { Component, OnInit, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { ReservationService } from '@app/reservation/reservation.service';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-reservation-import',
  templateUrl: './reservation-import.component.html',
  styleUrls: ['./reservation-import.component.scss']
})
export class ReservationImportComponent implements OnInit {
  csvData: any;
  csvHeader: any;
  charFind: any[] = ['Ã„', 'Ãœ', 'Ã–', 'Ã¼', 'Ã¶', 'Ã¤', 'ÃŸ'];
  charReplace: any[] = ['Ä', 'Ü', 'Ö', 'ü', 'ö', 'ä', 'ß'];
  mappingArray: any = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
  csvTemplate: string = 'Manuell';
  defaultValues: any = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
  placeholderValues: any = {
    0: '',
    1: '',
    2: '',
    3: 'z.B. 2:00',
    4: '',
    5: '',
    6: '',
    7: 'z.B. Daten importiert',
    8: '',
    9: ''
  };
  //'confirmed','canceled','noShow','arrived','placed','pending','finished','waiting'

  linesToImport: number = 0;
  linesImported: number = 0;

  msg: string = '';
  msgClass: string = '';
  tables: any = [];

  reservationData = {
    peopleCount: 0, // Personen Anzahl
    reservedFor: 0, // Datum + Uhrzeit
    reservedForU: 0,
    stayTime: 0, // Besuchsdauer
    gastId: 0, // Gast / Name ??
    gastIdE: 0, // Gast ??
    gastIdT: 0, // Gast ??
    notes: 0, // Notiz
    status: 0, // Status
    isTablePlan: 0, // ??
    msg: 0, //Msg
    firstName: 0 //firstName
    //betriebId:   0,
    //locked:      0, // Bestätigt ??
    //paymentTemplate: 0, // ??
    //hash:            0, // ??
  };
  textConstants: any = {
    peopleCount: 'Anz. der Besucher',
    reservedFor: 'Reserviert (Datum)',
    reservedForU: 'Reserviert (Uhrzeit)',
    stayTime: 'Dauer (Stunden) oder <br>Ende (Uhrzeit) des Besuchs',
    gastId: 'Name',
    gastIdE: 'E-Mail',
    gastIdT: 'Telefon',
    notes: 'Notizen',
    status: 'Status',
    isTablePlan: 'Tischnummer',
    msg: 'Gast Hinweis',
    firstName: 'First Name'
  };
  textConstantsArray: any[] = [];
  statusRewrite: string = '';
  statusListValue: any[] = [
    '',
    'pending',
    'confirmed',
    'canceled',
    'noShow',
    'arrived',
    'placed',
    'waiting',
    'finished'
  ];
  statusListName: any[] = [
    'Status überschreiben (optional)',
    'Ausstehend',
    'Bestätigt',
    'Storniert',
    'No Show',
    'Angekommen',
    'Platziert',
    'Warteliste',
    'Fertig'
  ];
  statusListSelected: 0;

  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;

  constructor(private papa: Papa, public reservationService: ReservationService) {
    this.textConstantsArray = Object.keys(this.reservationData);
  }

  fileChangeListener(event: any): void {
    this.msgClass = '';
    this.msg = '';
    let files = event.srcElement.files;

    if (files[0].name.endsWith('.csv')) {
      let input = event.target;
      let reader = new FileReader();

      reader.readAsText(input.files[0], 'utf8');
      reader.onload = data => {
        const csvString: any = reader.result || '';
        this.papa.parse(csvString, {
          complete: result => {
            this.csvData = result.data || [];
            this.csvHeader = this.csvData.splice(0, 1);
            // console.log('Parsed: ', this.csvHeader, this.csvData);
          }
        });
      };
    } else {
      if (`${files[0].name}`.toLowerCase().endsWith('.xlsx')) {
        let input = event.target;

        const target: DataTransfer = <DataTransfer>input;

        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.csvData = data || [];
          this.csvHeader = this.csvData.splice(0, 1);
          // console.log('Parsed: ', this.csvHeader, this.csvData);
        };
        reader.readAsBinaryString(target.files[0]);
        /*
        const CSV_TYPE ='text/csv;charset=utf-8';
        const CSV_EXTENSION = '.csv';
        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet);
        */
      } else {
        console.error('Falsche Dateiendung:', files[0].name);
        this.msgClass = 'alert alert-info';
        this.msg = 'Bitte importieren Sie eine gültige .csv/.xlsx Datei';
        this.fileImportInput.nativeElement.value = '';
      }
    }
  }

  encode_utf8(s: string) {
    return unescape(encodeURIComponent(s));
  }

  decode_utf8(s: string) {
    return decodeURIComponent(escape(s));
  }

  replaceArray(replaceString: string, find: any, replace: any) {
    if (typeof replaceString == 'string') {
      let regex;
      for (let i = 0; i < find.length; i++) {
        regex = new RegExp(find[i], 'g');
        replaceString = replaceString.substr(0, 60).replace(regex, replace[i]);
      }
    }
    return replaceString;
  }

  setCsvTemplate(template: string = 'Manuell') {
    this.csvTemplate = template;

    switch (template) {
      case 'Manuell':
        this.mappingArray = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '' };
        break;
      case 'Resmio':
        this.mappingArray = { 0: '10', 1: '2', 2: '1', 3: '', 4: '3', 5: '4', 6: '5', 7: '12', 8: '14', 9: '' };
        break;
      case 'Opentable':
        this.mappingArray = { 0: '1', 1: '5', 2: '0', 3: '', 4: '2', 5: '', 6: '3', 7: '4', 8: '6', 9: '' };
        break;
      case 'Quandoo':
        this.mappingArray = { 0: '12', 1: '8', 2: '9', 3: '10', 4: '3', 5: '5', 6: '6', 7: '18', 8: '11', 9: '14' };
        break;
      case 'Bookatable':
        this.defaultValues[3] = '2:00';
        this.mappingArray = { 0: '4', 1: '0', 2: '0', 3: '', 4: '1', 5: '', 6: '', 7: '6', 8: '3', 9: '' };
        break;
      default:
        this.mappingArray = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
        this.csvTemplate = 'Manuell';
        break;
    }
  }
  startCsvImport() {
    this.msg = '';
    this.msgClass = '';

    this.linesToImport = 0;
    this.linesImported = 0;

    this.csvData.forEach((row: any) => {
      if (row.length > 1) {
        this.linesToImport++;
      }
    });
    if (this.linesToImport <= 1000) {
      //const row = this.csvData[1];
      const delay = 150;
      let index = 0;
      let interval = setInterval(
        function(_this: any) {
          let row = _this.csvData[index] || null;

          if (row) {
            if (row.length <= 1) {
              index++;
              row = _this.csvData[index];
            }
            if (row.length > 1) {
              let dateParts: any = row[_this.mappingArray[1]].split('.');
              let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
              let reservedFor = moment(dateObject).format('YYYY-MM-DD');

              //_this.linesToImport++;
              let resData = {
                guestData: {
                  name: row[_this.mappingArray[4]],
                  email: row[_this.mappingArray[5]],
                  phone: row[_this.mappingArray[6]],
                  firstName: row[_this.mappingArray[11]]
                  //guestInform: []
                },
                stayTime: row[_this.mappingArray[3]] || null,
                guestCount: row[_this.mappingArray[0]] || 0,
                // reservedFor: row[_this.mappingArray[1]],
                reservedFor: reservedFor,
                time: row[_this.mappingArray[2]],
                //gastId: row[this.mappingArray[4]],
                //gastIdE: row[this.mappingArray[5]],
                //gastIdT: row[this.mappingArray[6]],
                notes: _this.replaceArray(row[_this.mappingArray[7]], _this.charFind, _this.charReplace),
                status: row[_this.mappingArray[8]],

                tags: '',
                staffId: '',
                locked: 0,
                sendCheckin: '',
                paymentTemplate: 0,
                // isTablePlan: row[_this.mappingArray[9]],
                isTablePlan: _this.findIdsByName(row[_this.mappingArray[9]]),
                msg: _this.replaceArray(row[_this.mappingArray[10]], _this.charFind, _this.charReplace)
              };

              resData.paymentTemplate = null;
              resData.staffId = null;
              let qdate = [];

              switch (_this.csvTemplate) {
                case 'Resmio':
                  resData.status = resData.status.replace('Gebucht', 'confirmed').replace('cancelled', 'canceled');
                  break;
                case 'Opentable':
                  let opentableDate = resData.reservedFor.split('/');
                  resData.reservedFor = `20${opentableDate[2]}-${opentableDate[1]}-${opentableDate[0]}`;
                  resData.status = resData.status.replace('Gebucht', 'confirmed').replace('cancelled', 'canceled');
                  break;
                case 'Bookatable':
                  let qtime: any = [];
                  if (resData.reservedFor.includes('-')) {
                    qdate = resData.reservedFor.split('-');
                    qtime = qdate[2].split(' ');
                    resData.reservedFor = `${qdate[0]}-${qdate[1]}-${qtime[0]} ${qtime[1]}`;
                  }
                  if (resData.reservedFor.includes('.')) {
                    qdate = resData.reservedFor.split('.');
                    qtime = qdate[2].split(' ');
                    resData.reservedFor = `${qtime[0]}-${qdate[1]}-${qdate[0]} ${qtime[1]}`;
                  }

                  resData.time = `${qtime[1]}`;
                  // console.log(277, _this.defaultValues);

                  resData.stayTime = Math.round(_this.parseTime(_this.defaultValues[3]));
                  resData.status = resData.status
                    .replace('RECORDED', 'pending')
                    .replace('CANCELED', 'canceled')
                    .replace('ARRIVED', 'arrived')
                    .replace('LEFT', 'confirmed');

                  resData.guestData.name += ', ' + row[2];
                  // console.log(287, resData);
                  break;
                case 'Quandoo':
                  qdate = resData.reservedFor.split('.');
                  resData.reservedFor = `${qdate[2]}-${qdate[1]}-${qdate[0]}`;
                  resData.stayTime = Math.round(
                    (_this.parseTime(resData.stayTime) - _this.parseTime(resData.time)) / 60
                  );
                  if (resData.status) {
                    resData.status = resData.status
                      .replace('CREATED', 'pending')
                      .replace('AUTOMATIC_CONFIRMED', 'confirmed')
                      .replace('CONFIRMED', 'confirmed')
                      .replace('REJECTED', 'canceled')
                      .replace('NOTIFICATION_REQUESTED', 'pending')
                      .replace('NOTIFIED', 'pending')
                      .replace('RECONFIRMED', 'confirmed')
                      .replace('CUSTOMER_CANCELED', 'canceled')
                      .replace('MERCHANT_CANCELED', 'canceled')
                      .replace('NO_SHOW', 'noShow')
                      .replace('CHECK_IN', 'arrived')
                      .replace('AUTOMATIC CHECK-OUT', 'confirmed')
                      .replace('CHECK_OUT', 'confirmed')
                      .replace('REVOKED', 'noShow');
                    // console.log(268, resData.status);
                  }
                  break;
                default:
                  break;
              }

              if (resData.status == '' || !resData.status) {
                resData.status = 'pending';
              }
              if (_this.statusRewrite && _this.statusRewrite != '') {
                resData.status = _this.statusRewrite;
              }

              if (_this.defaultValues[3] && _this.defaultValues[3] != '' && _this.csvTemplate !== 'Bookatable') {
                resData.stayTime = parseInt(_this.defaultValues[3].split(':')[0]);
                /*
                if (_this.defaultValues[3].includes(":")) {
                  resData.stayTime = Math.round(_this.parseTime(_this.defaultValues[3]) / 60);
                } else {
                  resData.stayTime = parseInt(_this.defaultValues[3]);
                  if (resData.stayTime > 0) {

                  } else {
                    resData.stayTime = 2;
                  }
                }
                */
              }

              if (_this.csvTemplate == 'Manuell') {
                if (resData.stayTime < 10) {
                  resData.stayTime = Math.round(_this.parseTime(`${resData.stayTime}:00`));
                }
              }
              if (_this.defaultValues[7] && _this.defaultValues[7] != '') {
                resData.notes = _this.defaultValues[7];
              }
              if (_this.defaultValues[10] && _this.defaultValues[10] != '') {
                resData.msg = _this.defaultValues[10];
              }
              // console.log('resData', resData);
              _this.processReservation(resData);
            }
          } else {
            index++;
            row = _this.csvData[index] || null;
          }
          if (++index === _this.csvData.length) {
            clearInterval(interval);
          }
        },
        delay,
        this
      );
    } else {
      this.msg =
        'Vorgang abgebrochen!<br />Sie versuchen mehr als 1000 Reservierungen auf einmal zu importieren. Bitte versuchen Sie es mit einer kürzeren CSV-Datei/Liste erneut.';
      this.msgClass = 'alert alert-danger';
    }
  }

  parseTime(s: string) {
    if (s) {
      const c = s.split(':');
      return parseInt(c[0]) * 60 + parseInt(c[1]);
    } else {
      return 0;
    }
  }

  processReservation(reservation: any) {
    /*
    const reservationData = {
      guestData: !this.isWalkIn ? reservationFormData.guestData : null,
      stayTime: reservationFormData.stayTime,
      guestCount: reservationFormData.guestCount,
      reservedFor: reservationFormData.date,
      time: reservationFormData.time,
      notes: reservationFormData.notes,
      status: reservationFormData.status,
      tags: reservationFormData.selectedTags,
      staffId: reservationFormData.staffId,
      locked: reservationFormData.lockTable,
      sendCheckin: reservationFormData.sendCheckin,
      paymentTemplate: reservationFormData.needsPayment ? reservationFormData.paymentTemplate : null,
      isTablePlan: this.selectedTables.join(',')
    };
    */
    this.reservationService
      .addReservation(reservation)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.linesImported++;
        // console.log('addReservation res:', res, 'reservation:', reservation);
        //this.passEntry.emit(reservation);;
      });
  }

  resetImport() {
    this.linesToImport = 0;
    this.linesImported = 0;
    this.csvData = '';
    this.csvTemplate = 'Manuell';
  }

  getTables() {
    this.reservationService
      .getAllTables()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((tables: any) => {
        this.tables = tables;
        console.log('tables=>', tables);
      });
  }

  ngOnInit() {
    this.getTables();
  }

  findIdsByName(names: any) {
    if (typeof names === 'string') {
      names = names.replace("'", '');
      names = names.toLowerCase();
      names = names.split(',').map((name: any) => name.trim());
    }
    let ids: any = [];
    this.tables.forEach((item: any) => {
      if (item.name) {
        item.name = item.name.toLowerCase();
        if (names && names.includes(item.name)) {
          ids.push(item.id);
        }
      }
    });
    if (ids && ids.length > 0) {
      return ids.join(',');
    } else {
      return '';
    }
  }
}
