<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="element-wrapper" style="padding-bottom: 0px;">
    <h6 class="element-header">
      {{ 'FeedBack.Statistic.GuestFeedbackStatisticDetails' | translate }}
    </h6>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="tab">
        <mat-tab label="{{ 'FeedBack.Statistic.Statistics' | translate }}">
          <div class="element-wrapper" style="padding-top: 1rem;" *ngIf="!feedbacks.length">
            <div class="element-box">
              <div class="alert alert-info">
                {{ 'FeedBack.Statistic.ThereAreNoGuestFeedbacks' | translate }}
              </div>
            </div>
          </div>
          <div class="element-wrapper" style="padding-top: 1rem;" *ngIf="feedbacks.length">
            <div class="row stat-box-row">
              <div class="col-sm-6 col-md-3">
                <div class="stat-box sb1 element-box">
                  <div class="title">{{ 'FeedBack.Statistic.InTotal' | translate }}</div>
                  <div class="number">{{ feedbacks.length }}</div>
                  <div class="text">{{ 'FeedBack.Statistic.FeedBacks' | translate }}</div>
                </div>
              </div>

              <div class="col-sm-6 col-md-3">
                <div class="stat-box sb2 element-box">
                  <div class="title">{{ 'FeedBack.Statistic.Average' | translate }}</div>
                  <div class="number">{{ stars[0].value.toFixed(2) }}</div>
                  <div class="text">{{ 'FeedBack.Statistic.InTotal' | translate }}</div>
                </div>
              </div>

              <div class="col-sm-6 col-md-3">
                <div class="stat-box sb3 element-box">
                  <div class="title">{{ 'FeedBack.Statistic.NumberOfFeedBacks' | translate }}</div>
                  <div class="number">{{ lastMonth }}</div>
                  <div class="text">{{ 'FeedBack.Statistic.LastMonth' | translate }}</div>
                </div>
              </div>

              <div class="col-sm-6 col-md-3">
                <div class="stat-box sb4 element-box">
                  <div class="title">{{ 'FeedBack.Statistic.NumberOfFeedBacks' | translate }}</div>
                  <div class="number">{{ thisMonth }}</div>
                  <div class="text">{{ 'FeedBack.Statistic.ThisMonth' | translate }}</div>
                </div>
              </div>
            </div>

            <div class="element-box">
              <h5>{{ 'FeedBack.Statistic.GivenStarOnAverage' | translate }}</h5>

              <ul class="rating-table">
                <ng-container *ngFor="let star of stars; let i = index">
                  <li class="li{{ i }}">
                    Ø {{ star.label }}
                    <span class="float-right">
                      <span class="stars">
                        <i class="fas fa-star" *ngIf="star.value >= 0.75"></i>
                        <i class="fas fa-star" *ngIf="star.value >= 1.75"></i>
                        <i class="fas fa-star" *ngIf="star.value >= 2.75"></i>
                        <i class="fas fa-star" *ngIf="star.value >= 3.75"></i>
                        <i class="fas fa-star" *ngIf="star.value >= 4.75"></i>

                        <i class="fas fa-star-half-alt" *ngIf="star.value >= 0.25 && star.value < 0.75"></i>
                        <i class="fas fa-star-half-alt" *ngIf="star.value >= 1.25 && star.value < 1.75"></i>
                        <i class="fas fa-star-half-alt" *ngIf="star.value >= 2.25 && star.value < 2.75"></i>
                        <i class="fas fa-star-half-alt" *ngIf="star.value >= 3.25 && star.value < 3.75"></i>
                        <i class="fas fa-star-half-alt" *ngIf="star.value >= 4.25 && star.value < 4.75"></i>

                        <i class="far fa-star" *ngIf="star.value < 4.25"></i>
                        <i class="far fa-star" *ngIf="star.value < 3.25"></i>
                        <i class="far fa-star" *ngIf="star.value < 2.25"></i>
                        <i class="far fa-star" *ngIf="star.value < 1.25"></i>
                        <i class="far fa-star" *ngIf="star.value < 0.25"></i>
                      </span>
                      {{ star.value.toFixed(2) }}
                    </span>
                  </li>
                </ng-container>
              </ul>

              <div class="chart-wrapper" *ngIf="showChart">
                <br />
                <h5>{{ 'FeedBack.Statistic.Statistics' | translate }}</h5>

                <div class="row">
                  <div class="form-group-statistic col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>{{ 'FeedBack.Statistic.DateFrom' | translate }}</label>
                      <br />

                      <select class="custom-select" [(ngModel)]="showMonths" (change)="setRatings()">
                        <option value="12">12 {{ 'FeedBack.Statistic.Month' | translate }}</option>
                        <option value="24">24 {{ 'FeedBack.Statistic.Month' | translate }}</option>
                        <option value="36">36 {{ 'FeedBack.Statistic.Month' | translate }}</option>
                        <option value="48">48 {{ 'FeedBack.Statistic.Month' | translate }}</option>
                        <option value="60">60 {{ 'FeedBack.Statistic.Month' | translate }}</option>
                        <option value="1200">{{ 'FeedBack.Statistic.WholeTimeSpan' | translate }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="chart-col col-lg-4 col-md-6">
                    <div class="chart-wrapper" *ngIf="showChart">
                      <canvas
                        baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="barChartLegend"
                        [chartType]="barChartType"
                        [colors]="barChartColors"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="chart-col col-lg-4 col-md-6">
                    <canvas
                      baseChart
                      [datasets]="barChartData1"
                      [labels]="barChartLabels1"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="barChartColors3"
                    >
                    </canvas>
                  </div>

                  <div class="chart-col col-lg-4 col-md-6">
                    <canvas
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="barChartColors1"
                    >
                    </canvas>
                  </div>

                  <div class="chart-col col-lg-4 col-md-6">
                    <canvas
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="barChartColors4"
                    >
                    </canvas>
                  </div>

                  <div class="chart-col col-lg-4 col-md-6">
                    <canvas
                      baseChart
                      [datasets]="barChartData4"
                      [labels]="barChartLabels4"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="barChartColors2"
                    >
                    </canvas>
                  </div>

                  <div class="chart-col col-lg-4 col-md-6">
                    <canvas
                      baseChart
                      [datasets]="barChartData5"
                      [labels]="barChartLabels5"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="barChartColors5"
                    >
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'FeedBack.Statistic.StatisticDetails' | translate }}">
          <div *ngIf="showStatisticDetails">
            <app-statistics-details></app-statistics-details>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'FeedBack.AI.FeedbackAI' | translate }}">
          <div class="element-wrapper">
            <div class="element-box">
              <div class="row">
                <div class="col-md-6">
                  <section>
                    <div class="form-group">
                      <label for=""> {{ 'Common.fromDate' | translate }}</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          [(ngModel)]="datesAI.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                          (ngModelChange)="validateDateRange()"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-6">
                  <section>
                    <div class="form-group">
                      <label for=""> {{ 'Common.ToDate' | translate }}</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateToPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>

                        <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateToInput
                          placeholder=""
                          [(ngModel)]="datesAI.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateToPicker"
                          (focus)="dateToPicker.open()"
                          (ngModelChange)="validateDateRange()"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <!-- Alert to show if to and from date is valid-->
              <div class="col-md-12" *ngIf="showErrorMessage">
                <p class="show-alert-msg">{{ 'Common.dateValidationError' | translate }}</p>
              </div>

              <div class="col-md-12">
                <section>
                  <div class="form-group actions">
                    <label for="">Aktionen</label><br />
                    <button class="btn btn-light" (click)="setDates('today')">heute</button>
                    <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>
                    <button class="btn btn-light" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                    <button class="btn btn-light" (click)="setDates('thisWeek')">akt. Woche</button>
                    <button class="btn btn-light" (click)="setDates('lastWeek')">letzte Woche</button>
                    <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>
                    <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>
                    <!-- <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small> -->
                  </div>
                </section>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">{{ 'FeedBack.AI.Question' | translate }}</label>
                  <textarea
                    class="form-control"
                    placeholder=""
                    type="text"
                    name="alert"
                    rows="5"
                    [(ngModel)]="question"
                    (ngModelChange)="refresh()"
                  ></textarea>
                </div>
              </div>

              <div class="col-sm-2">
                <button
                  class="btn btn-primary btn-block btn-lg"
                  [disabled]="disableBtn || !accessFeedbackAI"
                  (click)="!showErrorMessage ? AIIntegration() : ''"
                >
                  {{ 'Common.Submit' | translate }}
                </button>
              </div>

              <div class="col-md-12" *ngIf="!accessFeedbackAI" style="margin-top: 2%; font-size: 1.25rem;">
                <p class="show-alert-msg">{{ 'FeedBack.AI.msgForPremiumPaid' | translate }}</p>
              </div>

              <br />

              <div class="col-sm-12" *ngIf="result">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">{{ 'FeedBack.AI.Result' | translate }}</h5>
                    <div class="form-group">
                      <p class="card-text" [innerHTML]="result"></p>
                    </div>
                    <button class="btn btn-primary" (click)="copyToClipboard(result)">
                      {{ 'Common.CopyToClipboard' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
