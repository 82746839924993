import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelReservationComponent } from './hotel-reservation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule, MatDatepickerModule, MatAutocompleteModule } from '@angular/material';

import { SharedModule } from '@app/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeneratePdfReservationComponent } from './generate-pdf-reservation/generate-pdf-reservation.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { ReplaceGuestReservationComponent } from './replace-guest-reservation/replace-guest-reservation.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HotelReservationComponent, GeneratePdfReservationComponent, ReplaceGuestReservationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    SharedModule,
    NgSelectModule,
    MatAutocompleteModule,
    NgxDatatableModule,
    MomentModule,
    TranslateModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    })
  ],
  entryComponents: [ReplaceGuestReservationComponent]
})
export class HotelReservationModule {}
