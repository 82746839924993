import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as i0 from "@angular/core";
import * as i1 from "ng-recaptcha";
export class RecaptchaV3CustomService {
    constructor(recaptchaV3Service) {
        this.recaptchaV3Service = recaptchaV3Service;
    }
    execute(action) {
        return new Promise((resolve, reject) => {
            this.recaptchaV3Service.execute(action).subscribe((token) => {
                resolve(token); // Return the token
            }, error => {
                reject(error); // Handle error
            });
        });
    }
}
RecaptchaV3CustomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecaptchaV3CustomService_Factory() { return new RecaptchaV3CustomService(i0.ɵɵinject(i1.ReCaptchaV3Service)); }, token: RecaptchaV3CustomService, providedIn: "root" });
