<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      {{ 'HotelReservations.ReplaceExistingGuestReservation' | translate }} (#{{ reservation.id }})
    </h4>
    <form [formGroup]="reservationFormGroup">
      <div class="row">
        <div class="col-md-3">
          <h5>{{ 'HotelReservations.GuestDetail' | translate }}:</h5>
        </div>
        <div class="col-md-9">
          <span>{{ reservation?.guestData?.firstName }} {{ reservation?.guestData?.name }}</span>
          <div>
            {{ reservation?.guestData?.email }}
            <span *ngIf="reservation?.guestData?.email && reservation?.guestData?.phone"> | </span>
            {{ reservation?.guestData?.phone }}
          </div>
          <div>
            <i class="fas fa-user"></i> {{ reservation.peopleCount }}
            <span *ngIf="reservation.peopleCount > 1; else singlePerson">
              {{ 'HotelReservations.Persons' | translate }}
            </span>
            <ng-template #singlePerson> {{ 'HotelReservations.Person' | translate }}</ng-template>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <ng-select
              [searchable]="false"
              [clearable]="true"
              (change)="searchFreeTables()"
              formControlName="restaurant"
              [items]="allDataList.listOfRestaurants"
              bindLabel="name"
              bindValue="id"
              placeholder="('HotelReservations.SelectRestaurant' | translate )"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select
              [searchable]="false"
              [clearable]="true"
              formControlName="time"
              placeholder=" ( 'HotelReservations.SelectTime' | translate )"
              (change)="searchFreeTables()"
              bindLabel="time"
              bindValue="time"
              [items]="allDataList.listOfTimes"
            >
              <ng-template class="option-select" ng-label-tmp let-item="item">
                <div>{{ item.time }}</div>
              </ng-template>
              <ng-template
                class="option-select"
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div>{{ item.time }}</div>
                <div class="text-danger" *ngIf="item.isClosed" style="font-style:italic">
                  {{ 'HotelReservations.Closed' | translate }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select
              multiple="true"
              [items]="listOfTables"
              [searchable]="false"
              [clearable]="true"
              formControlName="table"
              [placeholder]="'HotelReservations.SelectTable' | translate"
              [loading]="tableLoader"
              bindLabel="table.name"
              bindValue="table.id"
            >
              <ng-template ng-label-tmp let-item="item"> Table {{ item.table.name }} </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="i">
                <div [ngClass]="{ 'not-available-item': item.isNotAvailable }">
                  Table {{ item.table.name }}
                  <span style="float:right"> <i class="fas fa-user"></i> {{ item.table.seats }} </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="updateReservation()">
        {{ 'HotelReservations.UpdateReservation' | translate }}
      </button>
    </form>
  </div>
</div>
