var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit, OnInit, NgZone } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavGroup } from './nav-item';
import { NavService } from '@app/nav.service';
import { AuthenticationService, CredentialsService } from '@app/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { NgScrollbar } from 'ngx-scrollbar';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { AddReservationComponent } from '@app/reservation/reservations/add-reservation/add-reservation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from '@app/core/search.service';
import { GuestComponent } from '@app/reservation/guest/guest.component';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { UtilService } from '@app/core/util.service';
import { UserService } from '../core/user.service';
import { MatSnackBar } from '@angular/material';
import { NewCompanyService } from '../new-company/new-company.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './theme.service';
export class ShellComponent {
    constructor(changeDetectorRef, media, navService, authService, credentialsService, route, router, formBuilder, bottomSheet, titleService, searchService, modalService, permissionsService, rolesService, utilService, userService, snackBar, newCompanyService, translate, themeService) {
        this.navService = navService;
        this.authService = authService;
        this.credentialsService = credentialsService;
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        this.bottomSheet = bottomSheet;
        this.titleService = titleService;
        this.searchService = searchService;
        this.modalService = modalService;
        this.permissionsService = permissionsService;
        this.rolesService = rolesService;
        this.utilService = utilService;
        this.userService = userService;
        this.snackBar = snackBar;
        this.newCompanyService = newCompanyService;
        this.translate = translate;
        this.themeService = themeService;
        this.showSidebar = true;
        this.showToolbar = true;
        this.showSearchbox = false;
        this.fixedTopGap = 56;
        this.userClients = [];
        this.showClientDropdown = false;
        this.uploadResponse = { status: '', message: '', filePath: '' };
        this.searchTerm$ = new Subject();
        this.isLoadingSearch = false;
        this.lightLogo = 'assets/logo.png';
        this.darkLogo = 'assets/logo_farbig-W.png';
        this.isNewClient = true;
        const { language } = JSON.parse(localStorage.getItem('credentials'));
        this.lang = language === 'GER' ? false : true;
        this.useLang(language === 'GER' ? 'de' : 'en');
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.showSidebar = window.innerWidth > 1100;
        router.events.subscribe((val) => {
            if (!(val instanceof NavigationEnd)) {
                return;
            }
            this.authService.getUserData().subscribe((user) => {
                if (user && Object.keys(user).length) {
                    this.userData = user;
                }
                else {
                    this.logout();
                }
            });
            this.scrollbarRef.scrollToTop();
        });
        this.refreshToken();
        this.getActiveClient();
        this.authService.activeClient.subscribe(client => {
            this.activeClient = client;
        });
        // Global search
        this.searchService.search(this.searchTerm$).subscribe(results => {
            this.searchSuggestions = results;
        });
        // Load news
        this.loadNews();
        this.translate.onLangChange.subscribe((event) => {
            this.setNavGroups(this.params);
        });
    }
    useLang(lang) {
        if (lang == 'de' && false) {
            this.translate.use('de-DE');
        }
        else {
            this.translate.use(lang);
        }
    }
    loadNews() {
        this.utilService.getNewsArticles().subscribe((newsArticles) => {
            if (newsArticles) {
                this.newsArticles = newsArticles;
            }
        });
    }
    // Switch Theme
    switchTheme(event) {
        const theme = event.checked ? 'dark-theme' : 'light-theme';
        this.changeTheme(theme);
        const html = document.querySelector('html');
        const logo = document.querySelector('.top-logo picture img').attributes;
        // console.log(logo.src.nodeValue);
        html.dataset.theme = theme;
        logo['src'].nodeValue = event.checked ? this.darkLogo : this.lightLogo;
    }
    changeTheme(theme) {
        this.themeService.setActiveTheme(theme);
    }
    onResize(event) {
        this.showSidebar = window.innerWidth > 1100;
    }
    onMove(event) {
        if (event.target.localName === 'mat-toolbar' || event.target.className === 'content-box') {
            event.preventDefault();
        }
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            avatar: ['']
        });
        const { language, role } = JSON.parse(localStorage.getItem('credentials'));
        this.lang = language === 'GER' ? false : true;
        this.isAccess = role === 'admin' ? true : false;
        this.getUserClients();
    }
    ngAfterViewInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.navService.appDrawer = this.appDrawer;
            this.navService.showToolbar$.subscribe((showToolbar) => {
                this.showToolbar = showToolbar;
            });
            this.navService.containerTopGap$.subscribe((containerTopGap) => {
                this.fixedTopGap = containerTopGap;
            });
        });
    }
    toggleSidebar() {
        this.navService.appDrawer.toggle().then(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }
    refreshToken() {
        this.authService.refreshToken().subscribe(res => { });
    }
    ngOnDestroy() {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }
    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('avatar').setValue(file);
        }
    }
    onFileSubmit() {
        const formData = new FormData();
        formData.append('file', this.form.get('avatar').value);
        /*
        this.uploadService.upload(formData, this.userId).subscribe(
          (res) => this.uploadResponse = res,
          (err) => this.error = err
        );
        */
    }
    getActiveClient() {
        if (this.route.firstChild) {
            this.routeSub = this.route.firstChild.params.subscribe((params) => {
                if (params.hasOwnProperty('clientId') !== '') {
                    this.params = params;
                    this.clientId = +params.clientId;
                    this.authService.setActiveClientId(+params.clientId);
                    this.authService.getClient(+params.clientId).subscribe(res => {
                        this.authService.changeActiveClient(res);
                        // Change title
                        this.titleService.setTitle(this.titleService.getTitle() + ' | ' + res.name);
                    }, error => {
                        console.log(error);
                        // User has no permission to access client
                        if (error.status === 403) {
                            this.router.navigateByUrl('/403', { skipLocationChange: true });
                        }
                        if (error.status === 404) {
                            this.router.navigateByUrl('/404', { skipLocationChange: true });
                        }
                    });
                    /*Get Client Res Settings*/
                    this.authService.getClientResSettings(this.clientId).subscribe((data) => {
                        if (data && Object.keys(data).length > 0) {
                            this.isNewClient = false;
                        }
                        this.setNavGroups(params);
                    });
                }
                else {
                    this.router.navigate(['/select']);
                }
            });
        }
        else {
            this.router.navigate(['/select']);
        }
    }
    setNavGroups(params) {
        let resMenu = {};
        if (this.isNewClient) {
            resMenu = {
                groupName: '',
                hidden: false,
                navItems: [
                    {
                        displayName: 'Reservierung',
                        iconName: 'icon icon-reservation',
                        route: 'client/' + params.clientId + '/reservation/demo',
                        children: [],
                        permission: ['admin', 'listReservations']
                    }
                ]
            };
        }
        else {
            resMenu = {
                groupName: 'Kommunikation',
                navItems: [
                    {
                        displayName: 'Reservierung',
                        iconName: 'icon icon-reservation',
                        route: 'client/' + params.clientId + '/reservation',
                        permission: [
                            'admin',
                            'listReservations',
                            'manageReservationAlerts',
                            'manageReservationSettings',
                            'viewWidgets',
                            'manageGuests'
                        ],
                        children: [
                            {
                                displayName: 'Reservierungsbuch',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/book',
                                children: [],
                                permission: ['admin', 'listReservations']
                            },
                            {
                                displayName: 'Hinweise',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/alerts',
                                children: [],
                                permission: ['admin', 'manageReservationAlerts']
                            },
                            {
                                displayName: 'Hotelgäste',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/hotel',
                                children: [],
                                permission: ['admin', 'listReservations']
                            },
                            {
                                displayName: 'Anzahlungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/transactions',
                                children: [],
                                permission: ['admin', 'listReservations']
                            },
                            {
                                displayName: 'Statistiken',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/statistics',
                                children: [],
                                permission: ['admin', 'listReservations']
                            },
                            {
                                displayName: 'Meine Gäste',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/my-guests',
                                children: [],
                                permission: ['admin', 'manageGuests']
                            },
                            {
                                displayName: 'Logfiles',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/logs',
                                children: [],
                                permission: ['admin', 'manageReservationLogs']
                            },
                            {
                                displayName: 'Einstellungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/settings',
                                children: [],
                                permission: ['admin', 'manageReservationSettings']
                            },
                            {
                                displayName: 'Räume und Tische',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/table-settings',
                                children: [],
                                permission: ['admin', 'manageReservationSettings']
                            },
                            {
                                displayName: 'Integration',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/integration',
                                children: [],
                                permission: ['admin', 'viewWidgets']
                            },
                            {
                                displayName: 'Telefonassistent',
                                iconName: '',
                                route: 'client/' + params.clientId + '/reservation/phone-assistant',
                                children: [],
                                permission: ['admin']
                            }
                            // {
                            //   displayName: 'Covid19 Datenerfassung',
                            //   iconName: '',
                            //   route: 'client/' + params.clientId + '/check-ins/',
                            //   children: [],
                            //   permission: ['admin', 'manageCheckins']
                            // }
                            /*
                            {
                              displayName: 'Import',
                              iconName: '',
                              route: 'client/' + params.clientId + '/reservation/import',
                              children: []
                            } */
                        ]
                    },
                    /*
                    {
                      displayName: 'GastroPay',
                      iconName: 'icon icon-gpay',
                      route: 'client/' + params.clientId + '/gastropay/',
                      children: [
                        {
                          displayName: 'Bestellungen',
                          iconName: '',
                          route: 'client/' + params.clientId + '/gastropay/orders',
                          children: []
                        }
                      ]
                    },
                    */
                    {
                        displayName: 'Bestellungen',
                        iconName: 'icon icon-gpay',
                        route: 'client/' + params.clientId + '/orders/',
                        permission: [
                            'admin',
                            'manageOrders',
                            'manageOrderSettings',
                            'exportOrderPayouts',
                            'manageCheckins',
                            'manageOrders'
                        ],
                        children: [
                            {
                                displayName: 'Übersicht',
                                iconName: '',
                                route: 'client/' + params.clientId + '/orders/overview',
                                children: [],
                                permission: ['admin', 'manageOrders']
                            },
                            {
                                displayName: 'Einstellungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/orders/settings',
                                children: [],
                                permission: ['admin', 'manageOrderSettings']
                            },
                            /*
                            {
                              displayName: 'Auszahlungen',
                              iconName: '',
                              route: 'client/' + params.clientId + '/orders/payout',
                              children: [],
                              permission: ['admin', 'exportOrderPayouts']
                            },
                            */
                            {
                                displayName: 'Export',
                                iconName: '',
                                route: 'client/' + params.clientId + '/orders/export',
                                children: [],
                                permission: ['admin', 'manageOrders']
                            },
                            {
                                displayName: 'Statistik',
                                iconName: '',
                                route: 'client/' + params.clientId + '/orders/statistic',
                                children: [],
                                permission: ['admin', 'manageOrders']
                            }
                        ]
                    },
                    {
                        displayName: 'Speisekarten',
                        iconName: 'icon icon-book-1',
                        route: 'client/' + +params.clientId + '/menucards',
                        children: [
                            {
                                displayName: 'Übersicht',
                                iconName: '',
                                route: 'client/' + +params.clientId + '/menucards/overview',
                                children: []
                            },
                            {
                                displayName: 'Artikelliste',
                                iconName: '',
                                route: 'client/' + +params.clientId + '/menucards/items',
                                children: []
                            },
                            {
                                displayName: 'Einstellungen',
                                iconName: '',
                                route: 'client/' + +params.clientId + '/menucards/settings',
                                children: []
                            },
                            {
                                displayName: 'CSV-Import',
                                iconName: '',
                                route: 'client/' + +params.clientId + '/menucards/import',
                                children: []
                            }
                        ]
                    },
                    {
                        displayName: 'Gästefeedback',
                        iconName: 'icon icon-feedback',
                        route: 'client/' + params.clientId + '/feedback',
                        permission: ['admin', 'viewFeedbacks'],
                        children: [
                            {
                                displayName: 'Übersicht',
                                iconName: '',
                                route: 'client/' + params.clientId + '/feedback/overview',
                                children: [],
                                permission: ['admin', 'viewFeedbacks']
                            },
                            {
                                displayName: 'Statistik',
                                iconName: '',
                                route: 'client/' + params.clientId + '/feedback/statistic',
                                children: [],
                                permission: ['admin', 'viewFeedbacks']
                            },
                            {
                                displayName: 'Integration',
                                iconName: '',
                                route: 'client/' + params.clientId + '/feedback/integration',
                                children: [],
                                permission: ['admin', 'viewFeedbacks']
                            },
                            {
                                displayName: 'Google Reviews',
                                iconName: '',
                                route: 'client/' + params.clientId + '/feedback/google-reviews',
                                children: [],
                                permission: ['admin', 'viewFeedbacks']
                            }
                        ]
                    },
                    {
                        displayName: 'Bewertungen',
                        iconName: 'icon icon-star',
                        disabled: true,
                        route: `https://www.gastroguide.de/business/reviews/index/id/${+params.clientId}`,
                        children: []
                    },
                    // {
                    //   displayName: 'Bewertungen',
                    //   iconName: 'icon icon-star',
                    //   route: `client/${params.clientId}/reviews/`,
                    //   children: []
                    // },
                    {
                        displayName: 'Jobbörse',
                        disabled: true,
                        iconName: 'icon icon-jobs',
                        route: `https://www.gastroguide.de/business/jobs/index/id/${+params.clientId}`,
                        children: []
                    }
                ]
            };
        }
        this.navGroups = [
            {
                groupName: 'Schnellzugriff',
                hidden: false,
                navItems: [
                    {
                        displayName: 'Meine Startseite',
                        iconName: 'icon icon-dashboard',
                        route: 'client/' + params.clientId + '/dashboard',
                        children: [],
                        permission: [],
                        disabled: false
                    }
                    /*
                    {
                      displayName: 'Neuer Beitrag',
                      iconName: 'icon icon-add-post',
                      route: `https://www.gastroguide.de/business/gastropush/post-news/id/${+params.clientId}`,
                      children: [],
                      permission: [],
                      disabled: true
                    },
                    {
                      displayName: 'Zeitversetzte Aufgaben',
                      iconName: 'icon icon-time-delayed',
                      route: `https://www.gastroguide.de/business/start/jobqueue/id/${+params.clientId}`,
                      children: [],
                      permission: [],
                      disabled: true
                    }
                    */
                ]
            },
            resMenu,
            {
                groupName: 'Gästemarketing',
                navItems: [
                    {
                        displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.Title'),
                        iconName: 'icon icon-credit-card',
                        route: 'client/' + params.clientId + '/vouchers/',
                        permission: [
                            'admin',
                            'listVouchers',
                            'manageVoucherSettings',
                            'manageVoucherPool',
                            'viewVoucherTransactions',
                            'manageVoucherOrders'
                        ],
                        children: [
                            {
                                displayName: 'Bestellungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/orders',
                                children: [],
                                permission: ['admin', 'manageVoucherOrders']
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.Settings'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/settings',
                                permission: ['admin', 'manageVoucherSettings'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.VoucherPool'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/pool',
                                children: [],
                                permission: ['admin', 'manageVoucherPool']
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.Transactions'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/transactions',
                                permission: ['admin', 'viewVoucherTransactions'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.Coupons'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/list',
                                permission: [
                                    'admin',
                                    'viewVoucherTransactions',
                                    'manageVoucherPool',
                                    'manageVoucherSettings',
                                    'manageVoucherOrders'
                                ],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.Vouchers.Statistics'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/vouchers/statistics',
                                permission: ['admin', 'viewVoucherTransactions'],
                                children: []
                            }
                        ]
                    },
                    {
                        displayName: this.translate.instant('ShellMenu.GuestMarketing.EventsAndTickets.Title'),
                        iconName: 'icon icon-ticket',
                        route: 'client/' + params.clientId + '/events/',
                        permission: ['admin', 'manageEvents'],
                        children: [
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.EventsAndTickets.AllEvents'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/events/all',
                                permission: ['admin', 'manageEvents'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.EventsAndTickets.AllAppointments'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/events/dates/all',
                                permission: ['admin', 'manageEvents'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.EventsAndTickets.Orders'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/events/orders',
                                permission: ['admin', 'manageEvents'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.EventsAndTickets.Settings'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/events/settings',
                                permission: ['admin', 'manageEvents'],
                                children: []
                            }
                        ]
                    },
                    {
                        displayName: 'Eigene Webseite',
                        iconName: 'icon icon-website',
                        disabled: true,
                        route: `http://www.gastroguide.de/business/gastro-web/index/id/${+params.clientId}`,
                        children: []
                    },
                    {
                        displayName: this.translate.instant('ShellMenu.GuestMarketing.OwnApp.Title'),
                        iconName: 'icon icon-smartphone',
                        route: 'client/' + params.clientId + '/gastro-app',
                        children: [
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.OwnApp.Statistics'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/gastro-app/statistics',
                                permission: ['admin'],
                                children: []
                            },
                            {
                                displayName: this.translate.instant('ShellMenu.GuestMarketing.OwnApp.RestaurantInfo'),
                                iconName: '',
                                route: 'client/' + params.clientId + '/gastro-app/restaurant-info',
                                permission: ['admin'],
                                children: []
                            }
                        ]
                    },
                    {
                        displayName: 'Aktionen',
                        disabled: true,
                        iconName: 'icon icon-bullhorn',
                        route: `https://www.gastroguide.de/business/betrieb-documents/index/betriebId/${+params.clientId}`,
                        children: []
                    },
                    {
                        displayName: this.translate.instant('ShellMenu.GuestMarketing.Documents.Title'),
                        iconName: 'icon icon-documents',
                        route: 'client/' + params.clientId + '/documents/',
                        children: []
                    }
                ]
            },
            {
                groupName: 'Einstellungen',
                navItems: [
                    {
                        displayName: 'Administration',
                        iconName: 'icon icon-settings-alt',
                        route: 'client/' + params.clientId + '/administration',
                        permission: ['admin', 'manageStaff', 'manageModerators'],
                        children: [
                            {
                                displayName: 'Benutzer verwalten',
                                iconName: '',
                                route: 'client/' + params.clientId + '/administration/users',
                                permission: ['admin', 'manageModerators'],
                                children: []
                            },
                            {
                                displayName: 'Mitarbeiter',
                                iconName: '',
                                route: 'client/' + params.clientId + '/administration/staff',
                                children: [],
                                permission: ['admin', 'manageStaff']
                            },
                            {
                                displayName: 'Allgemein',
                                iconName: '',
                                route: 'client/' + params.clientId + '/administration/general-setting',
                                children: [],
                                permission: ['admin', 'manageStaff']
                            },
                            {
                                displayName: 'Impressum',
                                iconName: '',
                                route: 'client/' + params.clientId + '/administration/imprint',
                                permission: ['admin'],
                                children: []
                            },
                            {
                                displayName: 'Newsletter-Empfänger',
                                iconName: '',
                                route: 'client/' + params.clientId + '/administration/news-letter',
                                permission: ['admin', 'manageStaff'],
                                children: []
                            }
                        ]
                    },
                    {
                        displayName: 'Öffnungszeiten',
                        iconName: 'icon icon-clock',
                        route: 'client/' + params.clientId + '/opening-hours',
                        children: [],
                        permission: ['admin', 'manageOpeningHours']
                    },
                    /*
                    {
                      displayName: 'Buchhaltung',
                      iconName: 'icon icon-book-1',
                      route: 'client/' + params.clientId + '/accounting',
                      children: [
                        {
                          displayName: 'Zahlungseinstellungen',
                          iconName: '',
                          route: 'client/' + params.clientId + '/accounting/payment-settings',
                          children: []
                        }
                      ]
                    },
                    */
                    {
                        displayName: 'Mein Eintrag',
                        iconName: 'icon icon-settings',
                        route: 'client/' + params.clientId + '/my-entry',
                        permission: ['admin', 'myEntry'],
                        children: [
                            {
                                displayName: 'Allgemein',
                                iconName: '',
                                route: 'client/' + params.clientId + '/my-entry/allgemein',
                                permission: ['admin', 'myEntry'],
                                children: []
                            },
                            {
                                displayName: 'Kontaktdaten',
                                iconName: '',
                                route: 'client/' + params.clientId + '/my-entry/kontaktdaten',
                                children: [],
                                permission: ['admin', 'myEntry']
                            }
                        ]
                    },
                    {
                        displayName: 'Galerie',
                        iconName: 'icon icon-images',
                        route: `client/${+params.clientId}/gallery`,
                        permission: ['admin', 'myEntry'],
                        children: [
                            {
                                displayName: 'Foto-Alben',
                                iconName: '',
                                route: `client/${params.clientId}/gallery/album`,
                                permission: ['admin', 'myEntry'],
                                children: []
                            }
                            // {
                            //   displayName: 'Vorschaubild einstellen',
                            //   iconName: '',
                            //   route: `client/${params.clientId}/gallery/preview-image`,
                            //   children: [],
                            //   permission: ['admin', 'manageStaff']
                            // }
                        ]
                    },
                    {
                        displayName: 'Logfiles',
                        iconName: 'icon icon-documents',
                        route: 'client/' + params.clientId + '/administration/change-log',
                        permission: ['admin'],
                        children: []
                    }
                ]
            },
            {
                groupName: 'Buchhaltung',
                navItems: [
                    {
                        displayName: 'Zahlungseinstellungen',
                        iconName: 'icon icon-settings-alt',
                        route: 'client/' + +params.clientId + '/accounting/payment-settings',
                        children: [],
                        permission: ['admin', 'managePaymentSettings']
                    },
                    {
                        displayName: 'Rechnungen',
                        iconName: 'icon icon-documents',
                        route: 'client/' + +params.clientId + '/accounting/invoices',
                        children: [],
                        permission: ['admin', 'managePaymentSettings']
                    },
                    {
                        displayName: 'Auszahlungen',
                        iconName: 'fas fa-shopping-bag',
                        route: 'client/' + params.clientId + '/accounting/payout/',
                        permission: [
                            'admin',
                            'manageOrders',
                            'manageOrderSettings',
                            'exportOrderPayouts',
                            'manageCheckins',
                            'manageOrders',
                            'managePayouts'
                        ],
                        children: [
                            {
                                displayName: 'Bestellungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/accounting/payout/orders',
                                children: [],
                                permission: ['admin', 'exportOrderPayouts', 'managePayouts']
                            },
                            {
                                displayName: 'Wertgutscheine',
                                iconName: '',
                                route: 'client/' + params.clientId + '/accounting/payout/vouchers',
                                children: [],
                                permission: ['admin', 'exportOrderPayouts', 'managePayouts']
                            },
                            {
                                displayName: 'Anzahlungen',
                                iconName: '',
                                route: 'client/' + params.clientId + '/accounting/payout/reservations',
                                children: [],
                                permission: ['admin', 'exportOrderPayouts', 'managePayouts']
                            },
                            {
                                displayName: 'Tickets',
                                iconName: '',
                                route: 'client/' + params.clientId + '/accounting/payout/tickets',
                                children: [],
                                permission: ['admin', 'exportOrderPayouts', 'managePayouts']
                            }
                        ]
                    }
                ]
            },
            {
                groupName: 'Developer',
                navItems: [
                    {
                        displayName: 'API-Schnittstelle',
                        iconName: 'icon icon-settings',
                        route: 'client/' + params.clientId + '/developer/api',
                        permission: ['admin', 'viewAPIKey'],
                        children: [
                            {
                                displayName: 'API-Key',
                                iconName: '',
                                route: 'client/' + params.clientId + '/developer/api/key',
                                children: [],
                                permission: ['admin', 'viewAPIKey']
                            },
                            {
                                displayName: 'Dokumentation',
                                disabled: true,
                                route: 'https://www.gastroguide.de/docs/api',
                                iconName: '',
                                children: [],
                                permission: ['admin', 'viewAPIKey']
                            }
                        ]
                    },
                    {
                        displayName: 'Reservierung',
                        iconName: 'icon icon-reservation',
                        route: 'client/' + params.clientId + '/developer/reservation',
                        permission: ['admin', 'viewWidgets'],
                        children: []
                    }
                ]
            }
        ];
    }
    getUserClients() {
        this.authService.getClients().subscribe(res => {
            this.userClients = res.clients;
        }, error => { });
    }
    toggleClientDropdown() {
        this.showClientDropdown = !this.showClientDropdown;
    }
    changeClient(clientId) {
        // this.router.navigateByUrl(this.router.url.replace(this.activeClient.id, clientId));
        this.showClientDropdown = false;
    }
    logoOptions() {
        this.bottomSheet.open(LogoOptionsComponent);
    }
    openSettings() { }
    changePassword() {
        this.router.navigate(['client/' + this.clientId + '/changepassword']);
    }
    addNewClient() {
        const { id } = JSON.parse(localStorage.getItem('credentials'));
        this.newCompanyService.getDraftData({ userId: id }).subscribe((response) => {
            if (response && response.length) {
                this.router.navigate(['/select-from-draft']);
            }
            else {
                this.router.navigate(['new-client']);
            }
        });
    }
    changeLang(event) {
        const creds = JSON.parse(localStorage.getItem('credentials'));
        const lang = event.checked ? 'EN' : 'GER';
        const data = { email: creds.email, clientId: creds.id, lang };
        this.userService.changeLanguage(data).subscribe((response) => {
            creds['language'] = lang;
            this.useLang(lang === 'GER' ? 'de' : 'en');
            localStorage.setItem('credentials', JSON.stringify(creds));
            this.snackBar.open(this.translate.instant('Common.LanguageChangedMessage'), '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }, (err) => {
            this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    logout() {
        this.authService.logout();
        this.switchTheme({ checked: false });
        // const theme = 'light-theme';
        // const html = document.querySelector('html');
        // const logo = document.querySelector('.top-logo picture img').attributes;
        // // console.log(logo.src.nodeValue);
        // html.dataset.theme = theme;
        // logo['src'].nodeValue = this.lightLogo;
        this.router.navigate(['/login']);
    }
    calculateScrollHeight() {
        // Substract 154px (64px toolbar + 90px client infobox) from the full height
        // to set scrollarea height
        return window.innerHeight - 154 + 'px';
    }
    openSearch() {
        this.showSearchbox = true;
        const offset = this.searchInput.nativeElement.getBoundingClientRect();
        this.searchBox.nativeElement.style.left = offset.x + 'px';
        this.searchBox.nativeElement.style.top = offset.y + 'px';
        setTimeout(() => {
            this.searchBoxInput.nativeElement.focus();
        });
    }
    closeSearch() {
        // Reset input value
        this.showSearchbox = false;
        this.searchBoxInput.nativeElement.value = '';
        this.searchTerm$.next('');
        this.searchSuggestions = null;
    }
    // Open Reservation from search
    openReservation(reservationId) {
        this.closeSearch();
        const appendContainer = 'body';
        const modalRef = this.modalService.open(AddReservationComponent, {
            size: 'lg',
            container: appendContainer
        });
        modalRef.componentInstance.reservationId = reservationId;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            modalRef.close();
        });
    }
    openGuest(guestId) {
        this.closeSearch();
        const appendContainer = 'body';
        const modalRef = this.modalService.open(GuestComponent, {
            size: 'lg',
            container: appendContainer
        });
        modalRef.componentInstance.guestId = guestId;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            modalRef.close();
        });
    }
    goToHelp() {
        window.open('https://blog.gastro.digital/', '_blank');
    }
}
export class LogoOptionsComponent {
    constructor(bottomSheetRef, authService, zone) {
        this.bottomSheetRef = bottomSheetRef;
        this.authService = authService;
        this.zone = zone;
        this.isUploading = false;
    }
    onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        if (this.selectedFile.type.split('/')[0] === 'image') {
            this.zone.run(() => {
                this.isUploading = true;
                const uploadData = new FormData();
                uploadData.append('file', this.selectedFile, this.selectedFile.name);
                this.authService.uploadLogo(uploadData).subscribe((settings) => {
                    this.isUploading = false;
                    this.authService.getClient(settings.id, true).subscribe(client => {
                        this.authService.changeActiveClient(client);
                    });
                    this.bottomSheetRef.dismiss();
                });
            });
        }
    }
    uploadLogo() { }
}
