import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Shift, Room } from '../reservation-settings.model';
import { Options, LabelType } from 'ng5-slider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
// import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { MenucardsService } from '@app/menucards/menucards.service';
import moment from 'moment';
import { EventsService } from '@app/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.scss']
})
export class AddShiftComponent implements OnInit, OnDestroy {
  @Input() public editShiftData: any; // only if editing
  @Input() public generalSettings: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  shiftFormGroup: FormGroup;
  maxGuestsPerUnit: FormArray;
  globalQuestions: any = [];
  paymentTemplates: any = [];
  questions: FormArray;
  isAccess: boolean;
  shiftTypes: Array<any> = [];
  bookingType: any = {};
  stayTimes: any = [
    {
      guestCountFrom: 1,
      guestCountTo: 2,
      minStayTime: 30,
      maxStayTime: 180,
      defaultStayTime: 60
    },
    {
      guestCountFrom: 3,
      guestCountTo: 5,
      minStayTime: 60,
      maxStayTime: 240,
      defaultStayTime: 120
    },
    {
      guestCountFrom: 6,
      guestCountTo: null,
      minStayTime: 90,
      maxStayTime: 240,
      defaultStayTime: 150
    }
  ];
  bufferTimes: Array<any> = [
    {
      label: 'Keine Pufferzeit',
      value: 0
    },
    {
      label: '30 Minuten',
      value: 0.5
    },
    {
      label: '60 Minuten',
      value: 1
    },
    {
      label: '90 Minuten',
      value: 1.5
    }
  ];

  cleaningTimes = [
    { label: 'Keine Reinigungsaufwand', value: 0 },
    { label: '5 Minuten', value: 5 },
    { label: '10 Minuten', value: 10 },
    { label: '15 Minuten', value: 15 },
    { label: '20 Minuten', value: 20 },
    { label: '30 Minuten', value: 30 },
    { label: '60 Minuten', value: 60 }
  ];

  stayTimeIntervals = [
    { label: '10 Minuten', value: 10 },
    { label: '15 Minuten', value: 15 },
    { label: '20 Minuten', value: 20 },
    { label: '30 Minuten', value: 30 },
    { label: '45 Minuten', value: 45 },
    { label: '60 Minuten', value: 60 },
    { label: '75 Minuten', value: 75 },
    { label: '90 Minuten', value: 90 },
    { label: '120 Minuten', value: 120 }
  ];

  stayTimeInterval: any;

  reservationIntervals: Array<any> = [
    {
      label: '10 Minuten',
      value: 10
    },
    {
      label: '15 Minuten',
      value: 15
    },
    {
      label: '30 Minuten',
      value: 30
    },
    {
      label: '45 Minuten',
      value: 45
    },
    {
      label: '60 Minuten',
      value: 60
    }
  ];
  rooms: Array<Room>;
  addOns: any = [];
  menuItems: any = [];
  activeEventList: any = [];
  activeTicketList: any = [];
  staticStayTimeOptions: Options;
  sliderOptions: Options;
  addObservable: any;
  editObservable: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private menucardsService: MenucardsService,
    private eventsService: EventsService,
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: any) => {});
    this.shiftFormGroup = this.formBuilder.group({
      color: ['#F44336'],
      name: ['', Validators.required],
      description: [''],
      info: [''],
      emailInfo: [''],
      rooms: [[], Validators.required],
      minGuests: [1],
      maxGuests: [10],
      buffer: [1, Validators.required],
      interval: [15, Validators.required],
      stayTimeType: ['static'],
      maxGuestsMsg: [''],
      noSlotAvailableMsg: [''],
      addOns: [[]],
      menuItem: [''],
      type: [0, Validators.required],
      amountLabelSingle: [''],
      amountLabelPlural: [''],
      stayTimes: this.formBuilder.array([
        this.formBuilder.group({
          guestCountFrom: 1,
          guestCountTo: 2,
          minStayTime: 30,
          maxStayTime: 180,
          defaultStayTime: 60
        }),
        this.formBuilder.group({
          guestCountFrom: 3,
          guestCountTo: 5,
          minStayTime: 60,
          maxStayTime: 240,
          defaultStayTime: 120
        }),
        this.formBuilder.group({
          guestCountFrom: 6,
          guestCountTo: null,
          minStayTime: 90,
          maxStayTime: 240,
          defaultStayTime: 150
        })
      ]),
      maxGuestsPerUnit: this.formBuilder.array([]),
      questions: this.formBuilder.array([]),
      noReservationAfterShiftStart: [false],
      groupReservation: [false],
      paymentTemplate: [],
      eventId: [],
      ticketId: [],
      isAutomatic: false,
      autoPlace: false,
      cleaningTime: [0],
      capacityType: false,
      stayTimeInterval: [30]
    });

    for (let index = 2; index <= 24; index++) {
      this.bufferTimes.push({
        label: index + (index === 1 ? ' Stunde' : ' Stunden'),
        value: index
      });
    }
    this.shiftTypes = [
      {
        label: this.translate.instant('ResSettings.ResAddShift.Default'),
        value: 0
      },
      {
        label: this.translate.instant('ResSettings.ResAddShift.Table'),
        value: 2
      },
      {
        label: this.translate.instant('ResSettings.ResAddShift.Ticket'),
        value: 1
      }
    ];
  }

  get formData() {
    return <FormArray>this.shiftFormGroup.get('maxGuestsPerUnit');
  }

  get questionsFormData() {
    return <FormArray>this.shiftFormGroup.get('questions');
  }

  getQuestions() {
    this.reservationService
      .getQuestions()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((questions: any) => {
        this.globalQuestions = questions;
      });
  }

  getPaymentTemplates() {
    this.reservationService
      .getPaymentTemplates()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((paymentTemplates: any) => {
        this.paymentTemplates = paymentTemplates;
      });
  }

  createUnit(): FormGroup {
    return this.formBuilder.group({
      day: [0, Validators.required],
      time: ['', Validators.required],
      guestCount: ['', Validators.required]
    });
  }

  createQuestion(): FormGroup {
    return this.formBuilder.group({
      questionId: [Validators.required],
      required: ['0', Validators.required],
      questionType: [0, Validators.required]
    });
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return value + ' Stunden';
  }

  async ngOnInit() {
    console.log('settings', this.generalSettings);
    const { role } = JSON.parse(localStorage.getItem('credentials'));
    this.isAccess = role === 'admin' ? true : false;
    this.getMenucards();
    this.getQuestions();
    this.getPaymentTemplates();
    await this.getAllActiveEvents();
    if (this.editShiftData) {
      this.shiftFormGroup.addControl('id', new FormControl(this.editShiftData.id, Validators.required));
      this.shiftFormGroup.get('color').setValue(this.editShiftData.color);
      this.shiftFormGroup.get('name').setValue(this.editShiftData.name);
      this.shiftFormGroup.get('description').setValue(this.editShiftData.description);
      this.shiftFormGroup.get('info').setValue(this.editShiftData.info);
      this.shiftFormGroup.get('emailInfo').setValue(this.editShiftData.emailInfo);
      this.shiftFormGroup.get('rooms').setValue(this.editShiftData.rooms);
      this.shiftFormGroup.get('addOns').setValue(this.editShiftData.addOns);
      this.shiftFormGroup.get('menuItem').setValue(this.editShiftData.menuItem);
      this.shiftFormGroup.get('minGuests').setValue(this.editShiftData.minGuests);
      this.shiftFormGroup.get('maxGuests').setValue(this.editShiftData.maxGuests);
      this.shiftFormGroup.get('maxGuestsMsg').setValue(this.editShiftData.maxGuestsMsg);
      this.shiftFormGroup.get('noSlotAvailableMsg').setValue(this.editShiftData.noSlotAvailableMsg);
      this.shiftFormGroup.get('buffer').setValue(this.editShiftData.buffer);
      this.shiftFormGroup.get('interval').setValue(this.editShiftData.interval);
      this.shiftFormGroup.get('stayTimeType').setValue(this.editShiftData.stayTimeType);
      this.shiftFormGroup.get('paymentTemplate').setValue(this.editShiftData.paymentTemplate);
      this.shiftFormGroup.get('groupReservation').setValue(this.editShiftData.groupReservation);
      this.shiftFormGroup.get('eventId').setValue(this.editShiftData.eventId);
      this.getAllRelatedTicket();
      this.shiftFormGroup.get('ticketId').setValue(this.editShiftData.ticketId);
      this.shiftFormGroup.get('type').setValue(this.editShiftData.type);
      this.shiftFormGroup.get('isAutomatic').setValue(this.editShiftData.isAutomatic);
      this.shiftFormGroup.get('autoPlace').setValue(this.editShiftData.autoPlace);
      this.shiftFormGroup.get('capacityType').setValue(this.editShiftData.capacityType);
      this.shiftFormGroup.get('cleaningTime').setValue(this.editShiftData.cleaningTime || 0);
      this.shiftFormGroup.get('stayTimeInterval').setValue(this.editShiftData.stayTimeInterval || 30);

      if (
        this.editShiftData.tableLabel &&
        (this.editShiftData.tableLabel.length > 0 || Object.keys(this.editShiftData.tableLabel).length > 0)
      ) {
        this.shiftFormGroup.get('amountLabelSingle').setValue(this.editShiftData.tableLabel.amountLabelSingle);
        this.shiftFormGroup.get('amountLabelPlural').setValue(this.editShiftData.tableLabel.amountLabelPlural);
      }
      const stayTimes: any = [];
      this.stayTimes = [];
      this.editShiftData.stayTimes.forEach((st: any) => {
        // Round to the nearest multiple of stayTimeInterval
        const closestStayTime =
          Math.round(st.defaultStayTime / this.shiftFormGroup.value.stayTimeInterval) *
          this.shiftFormGroup.value.stayTimeInterval;
        st.defaultStayTime = closestStayTime;
        stayTimes.push(this.formBuilder.group(st));
        this.stayTimes.push(st);
      });
      this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));

      // Max guests per units
      const maxGuestsUnits: any = [];
      if (this.editShiftData.maxGuestsPerUnit) {
        this.editShiftData.maxGuestsPerUnit.forEach((st: any) => {
          maxGuestsUnits.push(this.formBuilder.group(st));
        });
        this.shiftFormGroup.setControl('maxGuestsPerUnit', this.formBuilder.array(maxGuestsUnits));
      }

      // Questions
      const questions: any = [];
      if (this.editShiftData.questions) {
        this.editShiftData.questions = this.editShiftData.questions.sort(
          (a: any, b: any) =>
            (a.ReservationShiftQuestion && a.ReservationShiftQuestion.id ? a.ReservationShiftQuestion.id : a.id) -
            (b.ReservationShiftQuestion && b.ReservationShiftQuestion.id ? b.ReservationShiftQuestion.id : b.id)
        );
        this.editShiftData.questions.forEach((st: any) => {
          let questionValue = st.ReservationShiftQuestion ? st.ReservationShiftQuestion : st;
          st.required = questionValue.required.toString();
          questions.push(this.formBuilder.group(st));
        });
        this.shiftFormGroup.setControl('questions', this.formBuilder.array(questions));
      }

      this.shiftFormGroup.get('noReservationAfterShiftStart').setValue(this.editShiftData.noReservationAfterShiftStart);
    }
    this.createStayTimeSliders();
    // Get available rooms
    this.reservationService
      .getRooms()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((rooms: any) => {
        this.rooms = rooms;
      });

    // Get available addOns
    this.reservationService
      .getReservationAddOnTemplates()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((addOns: any) => {
        this.addOns = addOns;
      });
    this.changeBookingType();
  }

  createStayTimeSliders() {
    const maxStayTimeSettings =
      this.generalSettings && this.generalSettings.maxStayTimeSetting
        ? this.generalSettings.maxStayTimeSetting * 60
        : 0;
    this.stayTimeInterval = this.shiftFormGroup.value.stayTimeInterval || 30;
    this.staticStayTimeOptions = {
      floor: this.stayTimeInterval,
      ceil: maxStayTimeSettings || 600,
      step: this.stayTimeInterval,
      showTicks: true,
      translate: (value: number, label: LabelType): string => {
        value = value / 60; // Convert to hours
        if (label !== LabelType.Floor && label !== LabelType.Ceil) {
          // switch (value) {
          //   case 1:
          //     return value + ' Stunde';
          //   default:
          //     return value + ' Stunden';
          // }
          if (value <= 0.5) {
            return value * 60 + ' Minuten'; // Show in minutes if ≤ 30 min
          } else if (value === 1) {
            return value + ' Std';
          } else {
            return (value % 1 === 0 ? value : value.toFixed(2)) + ' Std';
          }
        } else {
          return value + '';
        }
      }
    };
    this.sliderOptions = {
      floor: this.stayTimeInterval,
      ceil: maxStayTimeSettings || 240,
      step: this.stayTimeInterval,
      showTicks: true,
      translate: (value: number, label: LabelType): string => {
        value = value / 60; // Convert to hours
        // switch (label) {
        //   case LabelType.Low:
        //     if (value === 0.5) {
        //       return '30 Minuten';
        //     } else if (value === 1) {
        //       return value + ' Stunde';
        //     } else {
        //       return value + ' Stunden';
        //     }
        //   case LabelType.High:
        //     if (value === 0.5) {
        //       return '30 Minuten';
        //     } else if (value === 1) {
        //       return value + ' Stunde';
        //     } else {
        //       return value + ' Stunden';
        //     }
        //   default:
        //     return value + '';
        // }
        switch (label) {
          case LabelType.Low:
          case LabelType.High:
            if (value <= 0.5) {
              return value * 60 + ' Minuten'; // Show in minutes if ≤ 30 min
            } else if (value === 1) {
              return value + ' Std';
            } else {
              return (value % 1 === 0 ? value : value.toFixed(2)) + ' Std';
            }
          default:
            return value + '';
        }
      }
    };
  }

  getMenucards() {
    this.menucardsService.getClientMenus().subscribe((menus: any) => {
      this.menuItems = menus.filter((menu: any) => {
        if (!(menu.validUntil && moment(new Date()).isAfter(menu.validUntil)) && menu.isActive) {
          return menu;
        }
      });
    });
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  add() {
    const stayTimes: any = [];
    this.stayTimes.forEach((st: any) => {
      stayTimes.push(this.formBuilder.group(st));
    });
    this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));

    if (!this.shiftFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const addData = this.shiftFormGroup.value;
    if (addData.rooms.length) {
      addData.rooms = addData.rooms.map((room: any) => room.id);
    }
    if (addData.addOns && addData.addOns.length) {
      addData.addOns = addData.addOns.map((addOn: any) => addOn.id);
    }
    this.addObservable = this.reservationService
      .addShift(addData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.passEntry.emit(response);
      });
  }

  save() {
    const stayTimes: any = [];
    this.stayTimes.forEach((st: any) => {
      stayTimes.push(this.formBuilder.group(st));
    });
    this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));

    if (!this.shiftFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const editData = this.shiftFormGroup.value;
    if (editData.rooms.length) {
      editData.rooms = editData.rooms.map((room: any) => room.id);
    }
    if (editData.addOns && editData.addOns.length) {
      editData.addOns = editData.addOns.map((addOn: any) => addOn.id);
    }
    this.editObservable = this.reservationService
      .editShift(editData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.passEntry.emit(response);
      });
  }

  changeColor(event: any) {
    this.shiftFormGroup.get('color').setValue(event);
  }

  getDefaultValues(min: number, max: number) {
    const step = this.stayTimeInterval;
    return Array(Math.ceil((max - min) / step) + 1)
      .fill(min)
      .map((x, y) => x + y * step);
  }

  minGuestsChanged(event: any) {
    this.shiftFormGroup.get('minGuests').setValue(event);
  }

  maxGuestsChanged(event: any) {
    this.shiftFormGroup.get('maxGuests').setValue(event);
  }

  addNewUnit() {
    this.maxGuestsPerUnit = this.shiftFormGroup.get('maxGuestsPerUnit') as FormArray;
    this.maxGuestsPerUnit.push(this.createUnit());
  }

  removeUnit(index: number) {
    this.maxGuestsPerUnit = this.shiftFormGroup.get('maxGuestsPerUnit') as FormArray;
    this.maxGuestsPerUnit.removeAt(index);
  }

  addQuestion() {
    this.questions = this.shiftFormGroup.get('questions') as FormArray;
    this.questions.push(this.createQuestion());
  }

  removeQuestion(index: number) {
    this.questions = this.shiftFormGroup.get('questions') as FormArray;
    this.questions.removeAt(index);
  }

  changeQuestions(question: any, i: number) {
    if (this.questions && this.questions.length > 0 && this.questions.value[i]) {
      this.questions.value[i].questionType = question.questionType;
      if (this.questions.value[i].questionType == 3) {
        const myForm = (<FormArray>this.shiftFormGroup.get('questions')).at(i);
        myForm.get('required').setValue('1');
        myForm.get('questionType').setValue(3);
      }
    }
  }

  async getAllActiveEvents() {
    this.activeEventList = [];
    try {
      const events = await this.eventsService
        .getEvents('active')
        .pipe(takeUntil(this.ngUnsubscribe))
        .toPromise();
      this.activeEventList = events;
    } catch (error) {
      console.error('Error fetching events', error);
    }
  }

  getAllRelatedTicket() {
    this.shiftFormGroup.get('ticketId').setValue(null);
    this.activeTicketList = [];
    const eventId = this.shiftFormGroup.value.eventId;
    if (eventId) {
      const eventDetail = this.activeEventList.find((item: any) => item.id == eventId);
      if (eventDetail) {
        this.activeTicketList = eventDetail.tickets;
      }
    }
  }

  changeBookingType() {
    this.bookingType = this.shiftTypes.find(type => type.value == this.shiftFormGroup.value.type);
  }
}
