<div *ngIf="showBanner" class="banner">
  <p>
    Du hast noch {{ pendingInvoices }} offene Rechnungen mit einem Gesamtbetrag von {{ totalAmount }} EUR. Bitte
    begleiche diese zeitnah um ein reibungsloses Arbeiten zu ermöglichen.
    <button type="button" class="close" aria-label="Close" (click)="closeBanner()">
      <span aria-hidden="true">&times;</span>
    </button>
  </p>
  <button class="btn btn-light ng-star-inserted" (click)="navigateToInvoices()">Zu den Rechnungen</button>
</div>
