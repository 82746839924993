import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OccupancyIndexComponent } from './occupancy-index.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/occupancy',
      data: {
        breadcrumb: 'Belegungsplanübersicht'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: 'index',
          component: OccupancyIndexComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Belegungsplanübersicht',
            breadcrumb: 'Belegungsplanübersicht',
            permissions: {
              only: ['admin', 'listReservations'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OccupancyIndexRoutingModule {}
