<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-md-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'HotelReservations.Title' | translate }}
        </h6>
        <div class="element-box">
          <div class="row">
            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for=""> {{ 'HotelReservations.DateFrom' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateFromInput
                      placeholder=""
                      [(ngModel)]="dates.from"
                      type="text"
                      name="date"
                      [matDatepicker]="dateFromPicker"
                      (focus)="dateFromPicker.open()"
                      (ngModelChange)="validateDateRange()"
                    />
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for=""> {{ 'HotelReservations.DateFrom' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateToPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateToInput
                      placeholder=""
                      [(ngModel)]="dates.to"
                      type="text"
                      name="date"
                      [matDatepicker]="dateToPicker"
                      (focus)="dateToPicker.open()"
                      (ngModelChange)="validateDateRange()"
                    />
                  </div>
                </div>
              </section>
            </div>

            <!-- Single Button (when only one integration exists) -->
            <div class="col-md-3" *ngIf="!multipleIntegration && pmsIntegrations && pmsIntegrations.length">
              <div class="form-group">
                <label for="" style="visibility: hidden;">Integration button</label>
                <button
                  class="btn btn-primary btn-block res-hotel-btn"
                  [disabled]="loadingHotelRes"
                  (click)="triggerLoading(0)"
                >
                  <span *ngIf="loadingHotelRes; else singleIntegrationLabel">
                    <span class="loader-res"></span>
                  </span>
                  <ng-template #singleIntegrationLabel>
                    {{ 'HotelReservations.FetchHotelReservations' | translate }}
                  </ng-template>
                </button>
              </div>
            </div>

            <!-- ng-select Dropdown (when multiple integrations exist) -->
            <ng-template [ngIf]="multipleIntegration">
              <div class="col-md-3 form-group">
                <label for="" style="visibility: hidden;">Integration button</label>

                <!-- Dropdown to select integration -->
                <ng-select
                  [items]="pmsIntegrations"
                  bindLabel="label"
                  placeholder="{{ 'HotelReservations.SelectHotelIntegrations' | translate }}"
                  (change)="onSelectIntegration($event)"
                >
                </ng-select>
              </div>

              <div class="col-md-3 form-group">
                <label for="" style="visibility: hidden;">Integration button</label>

                <!-- Button to trigger selected integration -->
                <button
                  class="btn btn-primary btn-block res-hotel-btn"
                  [disabled]="!selectedIntegration || loadingHotelRes"
                  (click)="triggerLoading(pmsIntegrations.indexOf(selectedIntegration))"
                >
                  <span *ngIf="loadingHotelRes; else buttonLabel">
                    <span class="loader-res"></span>
                  </span>
                  <ng-template #buttonLabel>
                    {{ 'HotelReservations.FetchHotelReservations' | translate }}
                  </ng-template>
                </button>
              </div>
            </ng-template>
          </div>

          <div class="row">
            <div class="col-md-12">
              <section>
                <div class="form-group actions">
                  <label for="">{{ 'HotelReservations.PredefinedPeriods' | translate }}</label
                  ><br />
                  <button class="btn btn-light" (click)="setDates('thisWeek')">
                    {{ 'HotelReservations.CurrentWeek' | translate }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('nextWeek')">
                    {{ 'HotelReservations.NextWeek' | translate }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('next2Weeks')">
                    {{ 'HotelReservations.Next2Weeks' | translate }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('nextMonth')">
                    {{ 'HotelReservations.NextMonth' | translate }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('next2Months')">
                    {{ 'HotelReservations.Next2Months' | translate }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('next6Months')">
                    {{ 'HotelReservations.Next6Months' | translate }}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="element-box">
          <div class="row">
            <div class="col-md-4">
              <div class="element-wrapper">
                <div class="element-box hotel-res-list">
                  <!-- Top: Total Count -->
                  <div class="top-bar">
                    <div class="total-count">
                      {{ 'HotelReservations.TotalReservation' | translate }}: {{ hotelReservations.length }}
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="{{ 'HotelReservations.Search' | translate }}"
                        [(ngModel)]="searchTerm"
                        type="text"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text btn-primary search-btn" (click)="updatePagination()">
                          <i class="fas fa-search"></i>
                        </div>
                        &nbsp;
                        <div class="input-group-text btn-primary search-btn" *ngIf="searchTerm" (click)="clearSearch()">
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pagination Controls -->
                  <div class="pagination-controls" *ngIf="totalPages > 1">
                    <button class="btn btn-primary" (click)="prevPage()" [disabled]="currentPage === 1">
                      <<< {{ 'HotelReservations.Previous' | translate }}
                    </button>
                    <span>{{
                      'HotelReservations.PageStatus' | translate: { current: currentPage, total: totalPages }
                    }}</span>
                    <button class="btn btn-primary" (click)="nextPage()" [disabled]="currentPage === totalPages">
                      {{ 'HotelReservations.Next' | translate }} >>>
                    </button>
                  </div>

                  <!-- Reservation Card -->
                  <div class="card-grid">
                    <div class="card" *ngFor="let row of paginatedReservations">
                      <div class="card-header">
                        <div class="date-box">
                          {{ row.arrivalDate | amDateFormat: 'DD.MM.YYYY' }} →
                          {{ row.departureDate | amDateFormat: 'DD.MM.YYYY' }}
                          <div
                            class="card-actions btn btn-primary"
                            (click)="placeGuest(row); $event.stopPropagation()"
                            *ngIf="!row.placed"
                          >
                            <i class="fas fa-table"></i>
                          </div>
                          <div class="card-actions btn btn-primary" (click)="row.expanded = !row.expanded">
                            <i class="fas fa-plus"></i>
                          </div>
                        </div>

                        <div class="guest-info">
                          <span *ngIf="row.board">
                            <span class="badge" [ngStyle]="{ background: fetchColor(row.board) }">{{ row.board }}</span
                            >&nbsp;
                          </span>

                          <span class="guest-name">
                            {{ row.guestInfo?.name }} {{ row.guestInfo?.firstName }} ({{ row.guestCount }})
                          </span>
                        </div>
                      </div>

                      <!-- Expandable Section -->
                      <div class="card-details" *ngIf="row.expanded">
                        <p>
                          <strong>{{ 'HotelReservations.ReservationNumber' | translate }}:</strong>
                          {{ row.reservationNo }}
                        </p>
                        <p>
                          <strong>{{ 'HotelReservations.Room' | translate }}:</strong> {{ row.roomNo }}
                        </p>
                        <p *ngIf="row.notes">
                          <strong>{{ 'HotelReservations.Notes' | translate }}:</strong> {{ row.notes }}
                        </p>
                        <p *ngIf="row.guestInfo?.guestNotes">
                          <strong> {{ 'HotelReservations.GuestNotes' | translate }}:</strong>
                          {{ row.guestInfo?.guestNotes }}
                        </p>
                        <p *ngIf="row.guestInfo?.email">
                          <strong>{{ 'HotelReservations.Email' | translate }}:</strong> {{ row.guestInfo?.email }}
                        </p>
                        <p *ngIf="row.guestInfo?.phoneNo">
                          <strong>{{ 'HotelReservations.PhoneNumber' | translate }}</strong>
                          {{ row.guestInfo?.phoneNo }}
                        </p>
                        <ng-template [ngIf]="row.reservationInfo">
                          <div *ngFor="let item of row.reservationInfo | keyvalue">
                            <b>{{ item.key }}</b
                            >: {{ item.value ? item.value : '-' }}
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="mt-5" [formGroup]="reservationFormGroup">
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for=""> {{ 'HotelReservations.Arrival' | translate }}</label>
                        <div class="input-group">
                          <div class="input-group-prepend" (click)="arrivalDatePicker.open()">
                            <div class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </div>
                          </div>
                          <mat-datepicker #arrivalDatePicker (closed)="dateFromInput1.blur()"></mat-datepicker>
                          <input
                            class="form-control"
                            #dateFromInput1
                            formControlName="arrivalDate"
                            placeholder="{{ 'HotelReservations.SelectDate' | translate }}"
                            type="text"
                            name="arrivalDate"
                            [min]="minArrivalDate"
                            [matDatepicker]="arrivalDatePicker"
                            (focus)="arrivalDatePicker.open()"
                            (dateChange)="checkIsSelectedTimeIntervalValid()"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for=""> {{ 'HotelReservations.Departure' | translate }}</label>
                        <div class="input-group">
                          <div class="input-group-prepend" (click)="departureDatePicker.open()">
                            <div class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </div>
                          </div>
                          <mat-datepicker #departureDatePicker (closed)="dateFromInput2.blur()"></mat-datepicker>
                          <input
                            class="form-control"
                            #dateFromInput2
                            formControlName="departureDate"
                            placeholder="{{ 'HotelReservations.SelectDate' | translate }}"
                            type="text"
                            [min]="minDepartureDate"
                            name="departureDate"
                            [matDatepicker]="departureDatePicker"
                            (focus)="departureDatePicker.open()"
                            (dateChange)="checkIsSelectedTimeIntervalValid()"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label for=""> {{ 'HotelReservations.ReservationNumber' | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="reservationNumber"
                        type="text"
                        autocomplete="nope"
                        placeholder="{{ 'HotelReservations.ReservationNumber' | translate }}"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-4">
                      <label for=""> {{ 'HotelReservations.Name' | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="name"
                        type="text"
                        autocomplete="nope"
                        placeholder=" {{ 'HotelReservations.EnterName' | translate }}"
                      />
                    </div>

                    <div class="form-group col-md-4">
                      <label for=""> {{ 'HotelReservations.FirstName' | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="firstName"
                        type="text"
                        autocomplete="nope"
                        placeholder="{{ 'HotelReservations.EnterFirstName' | translate }}"
                      />
                    </div>

                    <div class="form-group col-md-4">
                      <label for=""> {{ 'HotelReservations.RoomNumber' | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="roomNumber"
                        type="text"
                        autocomplete="nope"
                        placeholder="{{ 'HotelReservations.RoomNumber' | translate }}"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.MealPlan' | translate }}</label>
                        <ng-select
                          [items]="mealOptions"
                          bindLabel="label"
                          bindValue="value"
                          placeholder="{{ 'HotelReservations.SelectMealPlan' | translate }}"
                          formControlName="board"
                          (change)="changeBoardOption()"
                          [ngClass]="{ 'border-danger': callPlaced && !reservationFormGroup.value.board }"
                        >
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.StayDuration' | translate }}</label>
                        <ng-select
                          [searchable]="false"
                          [clearable]="false"
                          formControlName="stayTime"
                          placeholder="{{ 'HotelReservations.StayDuration' | translate }}"
                        >
                          <ng-option *ngFor="let st of stayTimes" [value]="st">{{ st?.name }}</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="form-group">
                          <label for="">{{ 'HotelReservations.Tags' | translate }}</label>
                          <ng-select
                            [items]="tags"
                            [addTag]="false"
                            [hideSelected]="true"
                            multiple="true"
                            bindLabel="label"
                            formControlName="selectedTags"
                            placeholder="{{ 'HotelReservations.SelectTags' | translate }}"
                          >
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">
                                <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
                              </span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item">
                              <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.NumPersons' | translate }}</label>
                        <ng-select
                          [searchable]="false"
                          (change)="onNumberOfPersonsChange()"
                          [clearable]="false"
                          formControlName="numberOfPersons"
                          required
                        >
                          <ng-option *ngFor="let person of createNumValues(20); let i = index" value="{{ i + 1 }}">
                            {{ i + 1 }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.NumChildren' | translate }}</label>
                        <ng-select [searchable]="false" [clearable]="false" formControlName="numberOfKids" required>
                          <ng-option *ngFor="let person of createNumValues(21); let i = index" value="{{ i }}">{{
                            i
                          }}</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.NumHighChairs' | translate }}</label>
                        <ng-select
                          [searchable]="false"
                          [clearable]="false"
                          formControlName="numberOfHighChairs"
                          required
                        >
                          <ng-option *ngFor="let person of createNumValues(21); let i = index" value="{{ i }}">
                            {{ i }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.Intolerances' | translate }}</label>
                        <ng-select
                          multiple="true"
                          [searchable]="false"
                          [clearable]="false"
                          formControlName="incompatibilities"
                          placeholder="{{ 'HotelReservations.SelectIntolerance' | translate }}"
                        >
                          <ng-option *ngFor="let intolerance of incompatibilities" value="{{ intolerance.id }}">
                            {{ intolerance.longName }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label for=""> {{ 'HotelReservations.GuestNotes' | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="guestNotes"
                        type="text"
                        autocomplete="nope"
                        placeholder="{{ 'HotelReservations.GuestNotes' | translate }}"
                        disabled="true"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.GroupTags' | translate }}</label>
                        <textarea
                          class="form-control text-area-section"
                          rows="4"
                          formControlName="groupTags"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">{{ 'HotelReservations.FreeFieldNotes' | translate }}</label>
                        <textarea
                          class="form-control text-area-section"
                          rows="4"
                          formControlName="notes"
                          placeholder="{{ 'HotelReservations.EnterNotes' | translate }}"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row res-data-form" *ngIf="reservationForm.controls.length > 0">
                    <div class="col-md-12 table-responsive">
                      <table class="table table-striped">
                        <ng-container
                          formArrayName="reservations"
                          *ngFor="let reservation of reservationForm.controls; let i = index"
                        >
                          <thead *ngIf="reservation.value.resIndex == 1">
                            <tr>
                              <th scope="col" colspan="7"></th>
                            </tr>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{{ 'HotelReservations.Date' | translate }}</th>
                              <th scope="col">{{ 'HotelReservations.Meal' | translate }}</th>
                              <th scope="col">{{ 'HotelReservations.Area' | translate }}</th>
                              <th scope="col">{{ 'HotelReservations.Time' | translate }}</th>
                              <th scope="col">{{ 'HotelReservations.Table' | translate }}</th>
                            </tr>
                          </thead>

                          <tbody class="form-group">
                            <tr
                              formGroupName="{{ i }}"
                              [ngClass]="{
                                'is-first-meal-row': reservation.value.resIndex == 1,
                                'is-error-row': reservation.value.errorMessage
                              }"
                              *ngIf="reservation.value.resIndex"
                            >
                              <td style="width: 5%;">{{ reservation.value.resIndex }}</td>
                              <td style="width: 10%;">
                                <input
                                  class="form-control"
                                  disabled
                                  value="{{ reservation.value.date | date }}"
                                  type="text"
                                  autocomplete="nope"
                                  style="width: 120px;"
                                />
                              </td>
                              <td style="width: 10%;">
                                {{ mealTypeMap[reservation.value?.mealType] || reservation.value?.mealType }}
                              </td>
                              <td class="form-group" style="width: 20%;">
                                <ng-select
                                  [searchable]="reservation.value.isEditable"
                                  [clearable]="reservation.value.isEditable"
                                  (change)="searchFreeTables(i)"
                                  formControlName="restaurant"
                                  [items]="reservation.value.listOfRestaurants"
                                  bindLabel="name"
                                  bindValue="id"
                                  placeholder="Restaurant auswählen"
                                  [placeholder]="
                                    reservation.value.isEditable
                                      ? ('HotelReservations.SelectRestaurant' | translate)
                                      : ''
                                  "
                                  [disabled]="!reservation.value.isEditable"
                                  [ngClass]="{ 'label-like': !reservation.value.isEditable }"
                                >
                                </ng-select>
                              </td>
                              <td class="form-group" style="width: 5%;">
                                <ng-select
                                  [searchable]="reservation.value.isEditable"
                                  [clearable]="reservation.value.isEditable"
                                  formControlName="time"
                                  [placeholder]="
                                    reservation.value.isEditable ? ('HotelReservations.SelectTime' | translate) : ''
                                  "
                                  [disabled]="!reservation.value.isEditable"
                                  [ngClass]="{ 'label-like': !reservation.value.isEditable }"
                                  (change)="searchFreeTables(i)"
                                  bindLabel="time"
                                  bindValue="time"
                                  [items]="reservation.value.listOfTimes"
                                >
                                  <ng-template class="option-select" ng-label-tmp let-item="item">
                                    <div>{{ item.time }}</div>
                                  </ng-template>
                                  <ng-template
                                    class="option-select"
                                    ng-option-tmp
                                    let-item="item"
                                    let-index="index"
                                    let-search="searchTerm"
                                  >
                                    <div>{{ item.time }}</div>
                                    <div class="text-danger" *ngIf="item.isClosed" style="font-style:italic">
                                      {{ 'HotelReservations.Closed' | translate }}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td class="form-group" style="width: 20%;">
                                <ng-select
                                  [items]="reservation.value.listOfTables"
                                  [searchable]="reservation.value.isEditable"
                                  [clearable]="reservation.value.isEditable"
                                  formControlName="table"
                                  [placeholder]="
                                    reservation.value.isEditable ? ('HotelReservations.SelectTable' | translate) : ''
                                  "
                                  [disabled]="!reservation.value.isEditable"
                                  [ngClass]="{ 'label-like': !reservation.value.isEditable }"
                                  [loading]="reservation.value.tableLoader"
                                  bindLabel="table.name"
                                  bindValue="table.id"
                                  (change)="onTableChanged(i)"
                                  multiple="true"
                                >
                                  <ng-template ng-label-tmp let-item="item"> Table {{ item.table.name }} </ng-template>

                                  <ng-template ng-option-tmp let-item="item" let-index="i">
                                    <div [ngClass]="{ 'not-available-item': item.isNotAvailable }">
                                      Table {{ item.table.name }}
                                      <span style="float:right">
                                        <i class="fas fa-user"></i> {{ item.table.seats }}
                                      </span>
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td>
                                <button (click)="editReservation(i)" class="btn btn-light">
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button
                                  *ngIf="reservation.value.resIndex == 1"
                                  [ngClass]="{
                                    'btn-light': !reservation.value.restaurant || !reservation.value.time,
                                    'btn-primary': reservation.value.restaurant && reservation.value.time
                                  }"
                                  [disabled]="!reservation.value.restaurant || !reservation.value.time"
                                  (click)="callCopyAllItems(i, reservation.value.mealType)"
                                  class="btn"
                                  title="Kopieren"
                                >
                                  <i class="far fa-copy"></i>
                                </button>
                                <button
                                  class="btn btn-danger"
                                  (click)="removeReservationAtIndex(i)"
                                  matTooltip="Löschen"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                                <button
                                  *ngIf="reservation.value.errorMessage && reservation.value.table"
                                  (click)="replaceGuestReservation(i)"
                                  class="btn btn-light"
                                >
                                  <i class="fas fa-exchange-alt"></i>
                                </button>
                              </td>
                            </tr>

                            <tr class="table-is-not-free-error" *ngIf="reservation.value.errorMessage">
                              <td class="error-tr-td"></td>
                              <td class="error-tr-td"></td>
                              <td class="error-tr-td"></td>
                              <td colspan="3" class="error-tr-td">{{ reservation.value.errorMessage }}</td>
                            </tr>
                          </tbody>
                        </ng-container>
                      </table>
                      <button
                        class="btn btn-primary btn-lg btn-block save-button"
                        (click)="createReservation()"
                        [promiseBtn]="submitObservable"
                      >
                        {{ 'HotelReservations.CreateReservation' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
