<div class="element-wrapper">
  <div class="element-box">
    <!-- Loading Spinner -->
    <div class="spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <!-- Existing Integrations List (separate section) -->
    <div *ngIf="systemIntegrations.length > 1" class="mb-3">
      <h6>{{ 'PMSIntegration.ExistingIntegrations' | translate }}</h6>
      <div class="list-group">
        <div *ngFor="let integration of systemIntegrations" class="list-group-item">
          <strong>{{ integration.system }}</strong>
          <button class="btn btn-sm btn-primary ml-2" (click)="selectHotelSystem(integration.system)">
            {{ integration.data.length ? ('PMSIntegration.Edit' | translate) : ('PMSIntegration.Add' | translate) }}
          </button>
        </div>
      </div>
    </div>

    <!-- Row containing both the dropdown (left) and the form (right) -->
    <div class="row">
      <!-- Dropdown Column -->
      <div class="col-md-4">
        <div class="form-group">
          <h6 class="element-header">{{ 'PMSIntegration.SelectHotelIntegrations' | translate }}</h6>
          <ng-select
            [items]="hotelSystems"
            bindLabel="value"
            bindValue="id"
            [searchable]="false"
            [clearable]="false"
            placeholder="{{ 'PMSIntegration.SelectHotelIntegrations' | translate }}"
            [(ngModel)]="dropdownSelected"
            (change)="onDropdownChange($event)"
            appendTo="body"
          >
          </ng-select>
        </div>
      </div>

      <!-- Dynamic Form Column -->
      <div class="col-md-8" *ngIf="selectedHotelSystem">
        <form [formGroup]="dynamicForm" class="mt-3">
          <div class="row">
            <div *ngFor="let field of HOTEL_SYSTEM_FIELDS[selectedHotelSystem]" class="col-md-6 form-group">
              <label>{{ field.label }}</label>
              <input class="form-control" [formControlName]="field.key" [type]="field.type" />
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="buttonRows mt-3">
            <button class="btn btn-primary" type="button" (click)="submitData()">
              {{ 'PMSIntegration.SaveIntegration' | translate }}
            </button>
            <button class="btn btn-danger ml-2" type="button" (click)="deleteData()" [disabled]="!hasExistingData()">
              {{ 'PMSIntegration.DeleteIntegration' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
