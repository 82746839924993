<!-- <div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div> -->

<div class="row">
  <div class="col-sm-12">
    <div class="element-wrapper" style="padding-top: 1rem;">
      <div class="element-box">
        <div class="row">
          <div class="col-md-3 checkbox-item">
            <label>
              <input type="checkbox" [(ngModel)]="showReviewData.internalReviews" />
              <span> &nbsp; {{ 'FeedBack.Statistic.InternalReviews' | translate }}</span>
            </label>
          </div>
          <div class="col-md-3 checkbox-item">
            <label>
              <input type="checkbox" (change)="getAllBusinessLocations()" [(ngModel)]="showReviewData.googleReviews" />
              <span> &nbsp; {{ 'FeedBack.Statistic.GoogleReviews' | translate }}</span>
            </label>
          </div>

          <div class="col-md-4">
            <ng-template [ngIf]="showReviewData.googleReviews">
              <button *ngIf="!tokenValid" (click)="loginWithGoogle()" class="google-login-btn btn btn-primary">
                <img src="./assets/img/google-icon.png" alt="Google Logo" class="google-logo" />
                <span>{{ 'FeedBack.Statistic.LoginWithGoogle' | translate }}</span>
              </button>
              <div class="form-group" *ngIf="tokenValid">
                <!-- <label for="">{{ 'FeedBack.Statistic.SelectBusinessLocation' | translate }}</label> -->

                <ng-select
                  [disabled]="!googleBusinessLocations.length"
                  [searchable]="false"
                  [clearable]="true"
                  [(ngModel)]="location.id"
                  (ngModelChange)="selectLocation($event)"
                  placeholder="{{ 'FeedBack.Statistic.SelectBusinessLocation' | translate }}"
                >
                  <ng-option [value]="item.id" *ngFor="let item of googleBusinessLocations">{{ item.title }}</ng-option>
                </ng-select>
              </div>
            </ng-template>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-md-6">
            <section>
              <div class="form-group actions">
                <label for="">{{ 'FeedBack.Statistic.ChooseScale' | translate }}</label
                ><br />
                <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                  <i class="fas fa-times"></i>&nbsp;{{ 'FeedBack.Statistic.Search' | translate }}:&nbsp;{{ filterBy }}
                </button>
                <!-- show 30 days data -->
                <button class="btn btn-light btn-scale" [ngStyle]="buttonStyles" (click)="setDates('daily')">
                  {{ 'FeedBack.Statistic.Daily' | translate }}
                </button>
                <!-- show 30 weeks data -->
                <button class="btn btn-light btn-scale" [ngStyle]="buttonStyles1" (click)="setDates('weekly')">
                  {{ 'FeedBack.Statistic.Weekly' | translate }}
                </button>
                <!-- show 12 month data -->
                <button class="btn btn-light btn-scale" [ngStyle]="buttonStyles2" (click)="setDates('monthly')">
                  {{ 'FeedBack.Statistic.Monthly' | translate }}
                </button>
                <!-- Show weekdays -->
                <button class="btn btn-light btn-scale" [ngStyle]="buttonStyles3" (click)="setDates('lastSeven')">
                  {{ 'FeedBack.Statistic.Weekdays' | translate }}
                </button>
              </div>
            </section>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <section>
                  <div class="form-group">
                    <label for="">{{ 'FeedBack.Statistic.DateFrom' | translate }}</label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="dateFromPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput
                        placeholder=""
                        [(ngModel)]="dates.from"
                        type="text"
                        name="date"
                        [matDatepicker]="dateFromPicker"
                        (focus)="dateFromPicker.open()"
                        (ngModelChange)="validateDateRange()"
                      />
                    </div>
                  </div>
                </section>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-6">
                <section>
                  <div class="form-group">
                    <label for="">{{ 'FeedBack.Statistic.DateTo' | translate }}</label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="dateToPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>

                      <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateToInput
                        placeholder=""
                        [(ngModel)]="dates.to"
                        type="text"
                        name="date"
                        [matDatepicker]="dateToPicker"
                        (focus)="dateToPicker.open()"
                        (ngModelChange)="validateDateRange()"
                      />

                      <div
                        class="input-group-append"
                        style="cursor: pointer;"
                        (click)="!showErrorMessage ? getFeedbacks(false) : ''"
                      >
                        <div class="input-group-text primary bg-primary">
                          <i class="fas fa-search text-light"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Loading Overlay -->
      <div *ngIf="loading" class="loading-overlay">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <!-- Content -->
      <div *ngIf="!loading">
        <div class="element-wrapper" style="padding-top: 1rem;" *ngIf="!feedbacks.length">
          <div class="element-box">
            <div class="alert alert-info">
              {{ 'FeedBack.Statistic.ThereAreNoGuestFeedbacks' | translate }}
            </div>
          </div>
        </div>

        <div *ngIf="feedbacks.length">
          <div class="row stat-box-row">
            <div class="col-sm-6 col-md-3">
              <div class="stat-box sb1 element-box">
                <div class="title">{{ 'FeedBack.Statistic.InTotal' | translate }}</div>
                <div class="number">{{ feedbacks.length }}</div>
                <div class="text">{{ 'FeedBack.Statistic.FeedBacks' | translate }}</div>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="stat-box sb2 element-box">
                <div class="title">{{ 'FeedBack.Statistic.Average' | translate }}</div>
                <div class="number">{{ stars[0].value.toFixed(2) }}</div>
                <div class="text">{{ 'FeedBack.Statistic.InTotal' | translate }}</div>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="stat-box sb3 element-box">
                <div class="title">{{ 'FeedBack.Statistic.NumberOfFeedBacks' | translate }}</div>
                <div class="number">{{ lastMonth }}</div>
                <div class="text">{{ 'FeedBack.Statistic.LastMonth' | translate }}</div>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="stat-box sb4 element-box">
                <div class="title">{{ 'FeedBack.Statistic.NumberOfFeedBacks' | translate }}</div>
                <div class="number">{{ thisMonth }}</div>
                <div class="text">{{ 'FeedBack.Statistic.ThisMonth' | translate }}</div>
              </div>
            </div>
          </div>

          <div class="element-box">
            <h5>{{ 'FeedBack.Statistic.GivenStarOnAverage' | translate }}</h5>

            <ul class="rating-table">
              <ng-container *ngFor="let star of stars; let i = index">
                <li class="li{{ i }}">
                  Ø {{ star.label }}
                  <span class="float-right">
                    <span class="stars">
                      <i class="fas fa-star" *ngIf="star.value >= 0.75"></i>
                      <i class="fas fa-star" *ngIf="star.value >= 1.75"></i>
                      <i class="fas fa-star" *ngIf="star.value >= 2.75"></i>
                      <i class="fas fa-star" *ngIf="star.value >= 3.75"></i>
                      <i class="fas fa-star" *ngIf="star.value >= 4.75"></i>

                      <i class="fas fa-star-half-alt" *ngIf="star.value >= 0.25 && star.value < 0.75"></i>
                      <i class="fas fa-star-half-alt" *ngIf="star.value >= 1.25 && star.value < 1.75"></i>
                      <i class="fas fa-star-half-alt" *ngIf="star.value >= 2.25 && star.value < 2.75"></i>
                      <i class="fas fa-star-half-alt" *ngIf="star.value >= 3.25 && star.value < 3.75"></i>
                      <i class="fas fa-star-half-alt" *ngIf="star.value >= 4.25 && star.value < 4.75"></i>

                      <i class="far fa-star" *ngIf="star.value < 4.25"></i>
                      <i class="far fa-star" *ngIf="star.value < 3.25"></i>
                      <i class="far fa-star" *ngIf="star.value < 2.25"></i>
                      <i class="far fa-star" *ngIf="star.value < 1.25"></i>
                      <i class="far fa-star" *ngIf="star.value < 0.25"></i>
                    </span>
                    {{ star.value.toFixed(2) }}
                  </span>
                </li>
              </ng-container>
            </ul>

            <div class="chart-wrapper" *ngIf="showChart">
              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <!-- <div class="chart-wrapper" *ngIf="showChart"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors"
                >
                </canvas>
                <!-- </div> -->
                <!-- </div> -->
              </div>
              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData1"
                  [labels]="barChartLabels1"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors3"
                >
                </canvas>
                <!-- </div> -->
              </div>
              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData2"
                  [labels]="barChartLabels2"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors1"
                >
                </canvas>
                <!-- </div> -->
              </div>

              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData3"
                  [labels]="barChartLabels3"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors4"
                >
                </canvas>
                <!-- </div> -->
              </div>

              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData4"
                  [labels]="barChartLabels4"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors2"
                >
                </canvas>
                <!-- </div> -->
              </div>

              <div class="row">
                <!-- <div class="chart-col col-lg-4 col-md-6"> -->
                <canvas
                  baseChart
                  [datasets]="barChartData5"
                  [labels]="barChartLabels5"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                  [colors]="barChartColors5"
                >
                </canvas>
                <!-- </div> -->
              </div>
              <hr />
              <!-- Doughnut Chart starts -->
              <h5>{{ 'FeedBack.Statistic.DistributionOfStarRatings' | translate }}</h5>
              <div class="row label-for-chart">
                <div class="color-box-wrapper">
                  <div class="color-box color-box-6"></div>
                  <p class="color-box-label">5</p>
                </div>
                <div class="color-box-wrapper">
                  <div class="color-box color-box-5"></div>
                  <p class="color-box-label">4</p>
                </div>
                <div class="color-box-wrapper">
                  <div class="color-box color-box-4"></div>
                  <p class="color-box-label">3</p>
                </div>
                <div class="color-box-wrapper">
                  <div class="color-box color-box-3"></div>
                  <p class="color-box-label">2</p>
                </div>
                <div class="color-box-wrapper">
                  <div class="color-box color-box-2"></div>
                  <p class="color-box-label">1</p>
                </div>
              </div>
              <div class="row">
                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[0].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData"
                      [labels]="doughnutChartLabels"
                      [options]="doughnutChatOption"
                      [plugins]="doughnutChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors"
                    >
                    </canvas>
                  </div>
                </div>
                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[1].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData1"
                      [labels]="doughnutChartLabels1"
                      [options]="doughnutChatOption1"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors1"
                    >
                    </canvas>
                  </div>
                </div>

                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[2].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData2"
                      [labels]="doughnutChartLabels2"
                      [options]="doughnutChatOption2"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors2"
                    >
                    </canvas>
                  </div>
                </div>
                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[3].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData3"
                      [labels]="doughnutChartLabels3"
                      [options]="doughnutChatOption3"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors3"
                    >
                    </canvas>
                  </div>
                </div>

                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[4].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData4"
                      [labels]="doughnutChartLabels4"
                      [options]="doughnutChatOption4"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors4"
                    >
                    </canvas>
                  </div>
                </div>

                <div class="chart-col col-lg-4 col-md-6">
                  <div class="service-title">
                    <span>{{ stars[5].label }}</span>
                  </div>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="doughnutChartData5"
                      [labels]="doughnutChartLabels5"
                      [options]="doughnutChatOption5"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="doughnutChartType"
                      [colors]="doughnutChartColors5"
                    >
                    </canvas>
                  </div>
                </div>
              </div>
              <!-- Doughnut Chart Ends -->
            </div>

            <hr />

            <h5>{{ 'FeedBack.Statistic.DailyView' | translate }}</h5>
            <ng-template #detailCategoryToggle>
              <div style="display: flex; align-items: center;">
                <h6 style="margin-right: 10px;">
                  {{ 'FeedBack.Statistic.ShowCategories' | translate }}
                </h6>
                <mat-slide-toggle
                  name="showDetailedReviews"
                  [(ngModel)]="showDetailedReviews"
                  style="margin-bottom: 4px;"
                >
                </mat-slide-toggle>
              </div>
              <br />
            </ng-template>

            <div *ngIf="showDetailedReviews; else showGeneralReviews">
              <div class="element-box datatable-res-transactions" *ngIf="dailyAverages.length">
                <ng-container *ngTemplateOutlet="detailCategoryToggle"> </ng-container>
                <ngx-datatable
                  class="material my-datatable striped"
                  [rows]="dailyAverages"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="0"
                  [rowHeight]="auto"
                  [limit]="tableLimit"
                  [sorts]="[{ prop: 'postedAt', dir: 'desc' }]"
                  [cssClasses]="{
                    pagerLeftArrow: 'fas fa-backward',
                    pagerRightArrow: 'fas fa-forward',
                    pagerPrevious: 'fas fa-step-backward',
                    pagerNext: 'fas fa-step-forward'
                  }"
                  [scrollbarH]="true"
                >
                  <ngx-datatable-column [sortable]="true" name="Datum" prop="postedAt">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span class="date-label" style="white-space: nowrap;"
                        >{{ row.postedAt | amDateFormat: 'DD-MM-YYYY' }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Anzahl" prop="averageAnswers">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span style="margin-left: 20px;"> {{ row.count }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-column [sortable]="true" name="Durch" prop="totalRating">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span> {{ row.totalRating }} </span>
                          </ng-template>
                        </ngx-datatable-column> -->

                  <ngx-datatable-column [sortable]="true" name="Ges" prop="generalRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.generalRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Serv" prop="serviceRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.serviceRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Saub" prop="sauberkeitRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.sauberkeitRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Ess" prop="essenRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.essenRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Amb" prop="ambienteRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.ambienteRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="P/L" prop="preisLeistungRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.preisLeistungRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
            <ng-template #showGeneralReviews>
              <div class="element-box datatable-res-transactions" *ngIf="dailyAveragesGoogle.length">
                <ng-container *ngTemplateOutlet="detailCategoryToggle"> </ng-container>
                <ngx-datatable
                  class="material my-datatable striped"
                  [rows]="dailyAveragesGoogle"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="0"
                  [rowHeight]="auto"
                  [limit]="tableLimit"
                  [sorts]="[{ prop: 'postedAt', dir: 'desc' }]"
                  [cssClasses]="{
                    pagerLeftArrow: 'fas fa-backward',
                    pagerRightArrow: 'fas fa-forward',
                    pagerPrevious: 'fas fa-step-backward',
                    pagerNext: 'fas fa-step-forward'
                  }"
                  [scrollbarH]="true"
                >
                  <ngx-datatable-column [sortable]="true" name="Datum" prop="postedAt">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span class="date-label" style="white-space: nowrap;"
                        >{{ row.postedAt | amDateFormat: 'DD-MM-YYYY' }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Anzahl" prop="averageAnswers">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span style="margin-left: 20px;"> {{ row.count }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="Ges" prop="generalRating">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.generalRating }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="1" prop="rating1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.rating1 }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="2" prop="rating2">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.rating2 }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="3" prop="rating3">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.rating3 }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="4" prop="rating4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.rating4 }} </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [sortable]="true" name="5" prop="rating5">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span> {{ row.rating5 }} </span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
